<template>
    <div class="container medium_container">
        <h1>Επικοινωνία</h1>
        <div class="content">
            <p class="text-center">Μπορείτε να επικοινωνήσετε μαζί μας με τους παρακάτω τρόπους: </p>

            <ul>
                <li>
                    <h5 class="text-center">Βρείτε μας στο facebook</h5>
                    <a class="primary_link" target="_blank" href="https://www.facebook.com/cookinghandbookgr">
                        <svg class="social_img"><use href="@/assets/icon_sprite.svg#fb_icon"/></svg> 
                        Cooking Handbook
                    </a>
                </li>
                <li>
                    <h5 class="text-center">Στείλτε μας μέσω email</h5>
                    <a class="primary_link" href="mailto:hello@handbook.gr">
                        <svg class="social_img"><use href="@/assets/icon_sprite.svg#mail_icon"/></svg> 
                        hello@handbook.gr
                    </a>
                </li>
                <li>
                    <h5 class="text-center">Συμπληρώστε την φόρμα</h5>

                    <form class="myForm form_frame" @submit="sendEmail">
                        <label for="name">Όνομα <MandatorySign v-bind:mandatory="false"/> </label> 
                        <input class="text_input" v-model="name" type="text" id="name" name="name" placeholder="Όνομα...">

                        <label for="email">Email <MandatorySign v-bind:mandatory="false"/> </label>
                        <input class="text_input" v-model="email" type="email" id="email" name="email" placeholder="Email...">

                        <label for="message">Μήνυμα <MandatorySign v-bind:mandatory="false"/> </label>
                        <textarea class="text_input" v-model="message" id="subject" name="message" placeholder="Μιλήστε μας..." style="height:200px"></textarea>

                        <vue-recaptcha
                            class="recaptcha_component"
                            @verify="onVerify"
                            ref="recaptcha"
                            sitekey="6LdeMH8aAAAAALyovrZpwaFG5cydhkMdoAPnDIFj">
                        </vue-recaptcha>
                        <!-- <div class="g-recaptcha" id="g-recaptcha" data-sitekey="6LdeMH8aAAAAALyovrZpwaFG5cydhkMdoAPnDIFj"></div> -->
                        <p v-if="errorMessage" class="error_message ">{{errorMessage}}</p>
                        <input class="primary_btn form_btn" type="submit" value="Αποστολή">

                    </form>
                </li>
            </ul>

        </div>

        <ActionModal  v-if="send"
        title="Επιτυχία"
        description="Ευχαριστούμε, το μήνυμά σας στάλθηκε με επιτυχία."
        action_btn="Τέλεια!"
        btn_type="action_btn"
        v-on:accept-action="send = false"
        v-on:decline-action="send = false"
        />
    </div>
</template>

<script>
import emailjs from 'emailjs-com';
import ActionModal from './extras/Action_modal.vue';
import MandatorySign from './extras/Mandatory_sign.vue'
import VueRecaptcha from 'vue-recaptcha';

export default {
    name: 'ContactUs',
      components: {
        ActionModal,
        MandatorySign,
        VueRecaptcha
    },
    data() {
        return {
            name:"",
            email:"",
            message:"",
            send:false,
            errorMessage:false,
            recaptcha:""
        }
    },

    methods:{
        onVerify(response) {
            this.recaptcha = response;
        },
        sendEmail(e) {
            e.preventDefault()
            try {
                if(this.recaptcha){                   
                    emailjs.sendForm('service_q1bjfnt', 'template_9ci38c9', ".myForm",
                        'user_OZ6d8S9eqlUnlCw3YE73Q', {
                        name: this.name,
                        email: this.email,
                        message: this.message
                    }) ;
                    this.send=true;

                    // Reset form field
                    this.name = ''
                    this.email = ''
                    this.message = ''
                } else {
                    this.errorMessage= "Είσαι ρομπότ;"
                }

            } catch(error) {
                console.log({error})
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .g-recaptcha {
        width: auto;
        margin-top: 2rem;
    }
    .content {
        svg {
            margin-right: 0.5rem;
        }
        ul {
            margin-top: 3rem;
            list-style: none;
            padding: 0;
            li {
                margin: 3rem 0;
            }
        }
        a {
            width:fit-content;
        }
        h5 { 
            margin-bottom: 2rem;
        }
    }
    .myForm {
        max-width: 600px;
    }

</style>
