<template>
  <div class=" top_heeader" >
    <div class="container">
        <div class="switch" @click="changeMode()">
            <p>{{mode}} mode</p>
            <div class="day" v-bind:class=" mode" >
                <div class="sun_border"></div>
                <div class="sun"></div>
                <div class="moon"></div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

export default {
    components: {
    },
    name: 'TopHeader',
    data(){
        return {
            mode: ""
        }
    },
    beforeMount(){
        this.initMode();
    },
    props: {
    },

    computed: {
    },
    methods:{
        initMode:function(){
            if (!localStorage.mode || localStorage.mode == "dark") {
                this.mode = "dark";
            }
            else if (localStorage.mode == "light") {
                this.mode="light";
            }
        },
        changeMode:function() {
            if (localStorage.mode && localStorage.mode == "dark") {
                localStorage.mode = "light";
                this.mode = "light";
                }
            else  {
                localStorage.mode = "dark";
                this.mode = "dark";
            }
            this.$emit('change-mode',this.mode);

        }
    }
}

</script>

<style scoped lang="scss">

.top_header {
    background-color: var(--color-darker-blue);
    height: 28px;
    margin: 0;
    color: white;
}
.dark .top_header {
    background-color: var(--color-text-primary);
}
.switch {
    display: inline-flex;
    float: right;
    align-items: center;
    margin: 0 1rem;
    cursor: pointer;
    p {
        margin: 0.2rem 0.7rem 0 0;
        font-style: italic;
        font-size: 0.8rem;
        opacity: 0.4;
        transition: all 0.2s ease;
    }
    .day {
        margin-top: 5px;
        float: right;
        height: 18px;
        width: 18px;
        transition: all 0.5s ease;
        * {
            transition: all 0.3s ease;
            border-radius: 50%;
        }
        .sun_border {
            height: 18px;
            width: 18px;
            position: absolute;
            border: dashed 1px gold;

        }
        .sun {
            background-color: gold;
            height: 10px;
            width: 10px;
            position: relative;
            margin: auto;
            top: 4px;
        }
        &.light {
            .sun_border{
                opacity: 0;
            }
            .sun {
                height: 16px !important;
                width: 16px !important;
                top: 0 !important;
            }
            .moon {
                background-color: var(--color-darker-blue);
                position: relative;
                height: 14px;
                width: 14px;
                top: -17px;
                left: -1px;
            }
        }
        &.dark {
            background-color: unset !important;
            .moon {
                background-color: var(--color-text-primary);
                height: 0rem;
                width: 0rem;
                top: -4px;
                left: -5px;
                position: relative;
            }
        }
    }
    &:hover {
        p {
            opacity: 0.6;
        }
        .sun {
            background-color:var(--color-orange);
        }
        .light {
            transform: rotate(25deg);
        }
        .dark {
            .sun {
                transform: scale(1.2);
            }
            .sun_border {
                transform: scale(1.1);
                
            }
        }
    }
}


</style>
