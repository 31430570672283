<template>
    <div class="recipes_cards row no-gutters">
        <div
            v-for="result in get_AND_Recipies"
            :key="result.id"
            class="col-lg-3 col-md-4 col-sm-6"
            >
            <RecipeCard v-bind:result="result" />
        </div>
    </div>
    
</template>

<script>
import RecipeCard from './Recipe_card.vue';


export default {
    name: 'RecipeDeck',
    components: {
        RecipeCard,

    },
    data(){
        return {  
        }
    },
    props: [ 
        // 'user',
        'results',
        'AND_max_length',
        'OR_max_length'
    ],
    computed: {
        get_AND_Recipies() {
            if (this.results.AND_match) {
                if (this.AND_max_length < this.results.AND_match.length) {
                return this.results.AND_match.slice(0,this.AND_max_length);
                } else {
                return this.results.AND_match;
                }
            }else {
                if (this.AND_max_length < this.results.length) {
                return this.results.slice(0,this.AND_max_length);
                } else {
                return this.results;
                }
            }
        },
        get_OR_Recipies() {
            if (this.results.OR_match) {
                if (this.OR_max_length < this.results.OR_match.length) {
                return this.results.OR_match.slice(0,this.OR_max_length);
                } else {
                return this.results.OR_match;
                }
            } else { return 0; }
        }
    },
    
    methods: {
        
    }
    
}
</script>

<style lang="scss" scoped>

</style>