<template>
  <Profile
    :profile="profile"
    :results="results"
  />
</template>

<script>
import { mapGetters } from "vuex";
import Profile from './extras/Profile.vue';


import store from "../store.js";
import firebase from "firebase";

export default {
  computed: {
      ...mapGetters({
        user: "user"
      })
  },
  watch: {
    '$route.params.username': function () {
      if (this.$route.params.username){
        fetch(process.env.VUE_APP_API+`/user/byUsername/`+this.$route.params.username)
          .then(res => res.json())
          .then(res => {
            if(res.error){
              this.error = res.error;
            }else{
              if(res.user && res.user.length > 0){
                this.profile = res.user[0];
                this.getRecipes();  
              }
            }
          })  
      }
    },
  },
  name: 'PublicProfile',
  components: {
    Profile

  },
  data(){
    return {
        results: [],
        profile: {},
       
    }
  },
  mounted(){
    if (this.$route.params.username){
      fetch(process.env.VUE_APP_API+`/user/byUsername/`+this.$route.params.username)
        .then(res => res.json())
        .then(res => {
          if(res.error){
            this.error = res.error;
          }else{
            if(res.user && res.user.length > 0){
              this.profile = res.user[0];
              this.getRecipes();  
            }
          }
        })  
    }
  },
  beforeMount(){
  },
  methods: {
    getRecipes: function() {
      this.results = [];
			fetch(process.env.VUE_APP_API+`/user/recipes/`+this.profile.email)
        .then(res => res.json())
        .then(res => {
            if(res.error){
                this.error = res.error;
            }else{
              if(res.length < 1){
                this.error = "No results";
                }else{
                  this.results = res;
                }
            }
        });
    },
  }
    
}
</script>

<style scoped>


</style>