<template>
   <button class="ingredient-chip" @click="clickChip" > 
  <!--<a class="ingredient-chip" :href="'/ingredients/'+ingredient.name" >-->
    <span class="ingredient-chip__img" 
      v-if="ingredient.image"
      :style="{ backgroundImage: 'url(' + ingredient.image + ')' }"> 
    </span>
    {{ingredient.name}}
  </button>
  <!-- </a> -->
</template>

<script>
export default {
  components: {
  },
  props: [
    "ingredient",
    "slided"
  ],
  name: 'IngredientChip',
  data() {
      return {
      }
  },
  methods:{

    clickChip() {
      if (!this.$props.slided) {
        this.$router.push({ path: '/ingredients/'+this.ingredient.name})
      }
    }
  }

}
</script>

<style lang="scss">

.ingredient-chip {
  font-weight: 600;
  border: 1px solid var(--color-darker-blue);
  border-radius: 3rem;
  padding: 0.2rem 1.2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: transparent;
  color: var(--color-text-primary);
  box-shadow: 0 5px 8px rgb(0 0 0 / 5%);
  transition: 0.2s all ease;
  display: flex;
  align-items: center;
  gap: 0.6rem;
  &:hover {
    border: 1px solid var(--color-secondary);
    box-shadow: 0 5px 8px rgba(0,0,0,0.16);
    text-decoration: none;
  }
}
.dark {
  .ingredient-chip {
    color: var(--color-text-primary-dark);
    &:hover {
      border: 1px solid var(--color-secondary);
      box-shadow: 0 5px 8px rgba(0,0,0,0.5);
    }
  }
}
.ingredient-chip__img {
  width: 3rem;
  height: 3rem;
  margin-left: -1rem;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
}

</style>
