<template>
    <div class="ingredient_form" >
        <div class="div-btn">
            <button type="button" @click="emitDeleteEvent" class="primary_btn del_btn"> - </button>
        </div>
        <div class="row">

            <div class="ing_name col-sm-3">
                <InputList 
                    data_url="/ingredients/getall"
                    v-model="name"
                    :inputValue="name"
                    v-bind:class="{ error: checkFieldError('name')}"
                    :placeholder="rand_txt(this.random_number,'na')"
                    v-on:input="updateValueToParent()" 
                 />
            </div>
            
            
            <div class="quantity col-sm-2">
                <!-- <label for="quantity">Ποσότητα:</label> -->
                <input type="number" v-on:input="updateValueToParent()" v-bind:class="{ error: checkFieldError('quantity')}" v-model="quantity" name="quantity" class="text_input" v-bind:placeholder="rand_txt(this.random_number,'qu')"  min="0">
            </div>
            <div class="units col-sm-3">
                <!-- <label for="units">Μ. μέτρησης:</label> -->
                <InputList 
                    v-if="name"
                    :data_url="'/ingredients/getByName/'+name+'?onlyUnits=true'"
                    v-model="units"
                    :inputValue="units"
                    v-bind:class="{ error: checkFieldError('units')}"
                    :placeholder="rand_txt(this.random_number,'un')"
                    v-on:input="updateValueToParent()" 
                 />
                <InputList 
                    v-if="!name"
                    data_url="/ingredients/getAllUnits"
                    v-model="units"
                    :inputValue="units"
                    v-bind:class="{ error: checkFieldError('units')}"
                    v-on:input="updateValueToParent()"
                    :placeholder="rand_txt(this.random_number,'un')"
                 />
            </div>
            <div class="details col-sm-4" >
                <!-- <label for="details">Λεπτομέρειες:</label> -->
                <input type="text"  v-model="details" v-on:input="updateValueToParent()"
                    name="details" class="text_input" v-bind:placeholder="rand_txt(this.random_number,'det')">
            </div>
        </div>


    </div>
</template>

<script>

import InputList from '../extras/Input_list.vue'

export default {
    name:'NewIngredient',
    components: {
        InputList,
    },
    props: [ 
        'id',
        'given_quantity',
        'errors',
        'given_units',
        'given_name',
        'given_details'
    ],
    data(){
        return {
            idA:this.$props.id,
            quantity:this.$props.given_quantity,
            units:this.$props.given_units,
            name:this.$props.given_name,
            details:this.$props.given_details,

            random_number:0
        }
    },
    beforeMount(){
        this.rand_num();
    },
    computed: {
      
        
    },
    methods:{
        updateValueToParent: function () {
            this.$emit('input', { 
                id:this.idA,
                quantity:this.quantity,
                units:this.units,
                name:this.name,
                details:this.details,
            })
        },
        emitDeleteEvent(){
            this.$emit("remove",this.idA)
        },
        checkFieldError(field_name){
          return this.$props.errors && this.$props.errors.errors[field_name] != null;
        },
        rand_num() {
            this.random_number = Math.floor(Math.random() * Math.floor(10));
        },
        rand_txt (n, a) {
            var random_placeholder=[
                {na:"ζάχαρη",       qu:2,   un:"κ.γλ.", det:"καστανή"}, 
                {na:"αυγό/α",       qu:3,   un:"τεμ",   det:""}, 
                {na:"μαρούλι/ια",   qu:1,   un:"τεμ",   det:"καλά πλυμένο"}, 
                {na:"κολοκυθάκι/ια", qu:400,un:"γρ.",   det:""}, 
                {na:"δυόσμο",       qu:1,   un:"κ.σ.",  det:"ψιλοκομμένο"}, 
                {na:"αλάτι",        qu:1,   un:"πρέζα", det:""}, 
                {na:"γιαούρτι",     qu:280,   un:"γρ.",   det:"στραγγιστό"}, 
                {na:"λεμόνι",       qu:1,   un:"τεμ",   det:"τον χυμό του"}, 
                {na:"κεράσια",       qu:200,   un:"γρ.",   det:""}, 
                {na:"αλεύρι",       qu:1.5,   un:"κούπες",   det:"κοσκινισμένο"}, 
            ];
            if (a=="na") {
                return random_placeholder[n].na;
            }
            else if (a=="qu") {
                return random_placeholder[n].qu;
            }
            else if (a=="un") {
                return random_placeholder[n].un;
            }
            else if (a=="det") {
                return random_placeholder[n].det;
            }
        }

    }
    
}
</script>

<style scoped>

.ingredient_form {
   display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    align-items: flex-start;
    
}
.ingredient_form div {
    display: flex;
}
.ingredient_form .row div {
    padding-right: 5px;
}
.div-btn {
    margin-top: 1rem;
}
.ingredient_form div * {
    width: 100%;
}
.autocomplete-list {
    position: absolute;
    margin-top: 4rem;
}
.autocomplete-list ul {
    width: 200px;
    z-index: 9;
    position: absolute;
    display: inline-block;
    background-color: white;
    border: 1px solid;
    list-style: none;
    padding: 0;
}

.autocomplete-list li {
    padding: 0.3rem 0.8rem;
}
.autocomplete-list li:hover {
    background-color: #f1f1f1;
}



</style>