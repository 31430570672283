<!--
<UnsavedDataPopup
  :diff = "cached_recipe_diff"
/>
-->
<template>
  <div v-if="diff" class="data-popup" v-bind:class="{ active: isActive }">
    <details>
      <summary class="data-popup__icon">!</summary>
      <div class="data-popup__content"> 
        Φαίνεται ότι έχεις κάνει αλλαγές στην συνταγή,<br> οι οποίες δεν έχουν αποθηκευτεί:
        <button v-if="diff.name.type=='updated'"        @click='$emit("edit-name")'         class="primary_link primary_link--white popup_link"> Αλλαγή στην επεξεργασία τίτλου</button>
        <!-- <button v-if="diff_name" href="/" class="primary_link primary_link--white"> Αλλαγή στην επεξεργασία τίτλου2</button> -->
        <button v-if="diff_ingredients"                 @click='$emit("edit-ingredients")'  class="primary_link primary_link--white popup_link"> Αλλαγές στην επεξεργασία υλικών</button>
        <button v-if="diff_utensils"                    @click='$emit("edit-utensils")'     class="primary_link primary_link--white popup_link"> Αλλαγές στην επεξεργασία σκευών</button>
        <button v-if="diff_steps"                       @click='$emit("edit-steps")'        class="primary_link primary_link--white popup_link"> Αλλαγές στην επεξεργασία βημάτων</button>
        <button v-if="diff_details"                     @click='$emit("edit-details")'      class="primary_link primary_link--white popup_link"> Αλλαγές στην επεξεργασία λεπτομερειών</button>
        <button v-if="diff.tips.type=='updated'"        @click='$emit("edit-tips")'         class="primary_link primary_link--white popup_link"> Αλλαγές στην επεξεργασία συμβουλών</button>
        <button v-if="diff_tags"                        @click='$emit("edit-tags")'         class="primary_link primary_link--white popup_link"> Αλλαγές στην επεξεργασία ετικετών</button>
        <button v-if="diff.description.type=='updated'" @click='$emit("edit-description")'  class="primary_link primary_link--white popup_link"> Αλλαγές στην επεξεργασία περιγραφής</button>
        <button v-if="diff" class="primary_link primary_link--white popup_link dashed-border-top"> Απόρριψη όλων</button>
       </div>   
  
    </details>
    <svg class="data-popup__close-icon" v-on:click="isActive=false"><use href="@/assets/icon_sprite.svg#x_icon"/></svg>   
  </div>
  <div v-else class="data-popup">
    <div class="data-popup__content"> 
        Δεν υπάρχουν προσωρινές αλλαγές.
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: [
    "diff"
  ],
  name: 'UnsavedDataPopups',
  data() {
      return {
        isActive:true,
        
      }
  },
  computed: {
    diff_ingredients() {
      let flag = false;
      console.log("eimai mesa");
      // console.log(this.diff.ingredients);
      // for (ingredient of this.diff.ingredients) {
      //   if (ingredient.type = "updated") {
      //     console.log("kati brika");
      //     flag = true;
      //     return true;
      //   }
      // } 
      // if (!flag) {
      //   return false;
      // }
      return true;
    },
    diff_utensils() {
      return false;
    },
    diff_steps() {
      return false;
    },
    diff_tags() {
      return false;
    },
    diff_details() {
      let flag = false;
      if (this.diff) {
        if (this.diff.cooking_time.type == "updated" ||
            this.diff.waiting_time.type == "updated" ||
            this.diff.portions.type == "updated" ||
            this.diff.difficulty.type == "updated"
        ) {
          flag = true;
        }
      }
      return flag; 
    },
    
  },
  methods:{
  },
}
</script>

<style lang="scss" scoped>

  details {
    transition: all 0.2s ease;
  }
  .data-popup {
    background-color: rgba(var(--color-primary-rgb),0.9);
    color: white;
    border-radius: 20px;
    text-align: right;
    position: fixed;
    width: fit-content;
    padding: 0.5rem 0.7rem;
    top: 1rem;
    right: 1rem;
    backdrop-filter: blur(4px);
    z-index:2;
    display: flex;
    column-gap: 0.5rem;
    align-items: flex-start;

    display:none;
  }
  .active {
    display: flex;
  }
  .dark {
    .data-popup {
      background-color: rgba(var(--color-primary-rgb),0.7);
    }
  }
  .data-popup__icon {
    background-color: white;
    font-weight: 700;
    color: var(--color-primary);
    padding: 0 10px;
    border-radius: 12px;
    width: fit-content;

  }
  .data-popup__close-icon {
    width: 0.8rem;
    height: 0.8rem;
    margin: 6px;
    fill: white;
    cursor: pointer;
  }
  .data-popup__content {
    margin-top: 0.6rem;    
    margin-left: 0.5rem;
  }
  .popup_link {
    font-style: italic;
    text-align: right;
    width: 100%;
  }
  .dashed-border-top {
    margin-top: 0.5rem !important;
    border-top: 1px dashed white !important;
    padding-top: 0.5rem !important;
  }
</style>