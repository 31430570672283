<template>
      <div class="ingredient_container container" v-if="results.length>0">

        <div class="row ingredient_header">
          <div class="col-sm-6 ">

            <div v-if="results[0].image">
              <div class="central_img" v-if="!img_isEditing"  :style="{ backgroundImage: 'url(' + results[0].image + ')' }"> </div>
            </div>
            <div  v-else>
              <div class="central_img no_photo_txt style-italic t-details-color" v-if="!img_isEditing">
                  {{ $t('info.no_image') }}
              </div>
            </div>
            <div v-if="img_isEditing">
              <p class="subtext">{{ $t('subtetexts.media') }}</p>
              <input type="file" v-on:change="handleFileChange()" ref="image" name="image" id="image" class="text_input img_input">
            </div>
            <div class="img_btn">
                <EditButton
                  v-if="user.data && (user.data.profile.admin || user.data.profile.editor)"
                    :isEditing="img_isEditing"
                    :I_am_the_author="I_am_the_author"
                    align="right"
                    text="εικόνας"
                    border="no-border"
                    v-on:edit-pressed="img_isEditing = true"
                    v-on:save-pressed="updateIngredientData().then(data=>{img_isEditing = false})"
                  />
            </div>

          </div>
          <div class="col-sm-6 ">
            <div class="first_info">
              <h1>{{results[0].name}}</h1>

              <h6 class="t-details-color font-size-xs style-italic">
                <svg class="ingr_details_icon"><use href="@/assets/icon_sprite.svg#portions"/></svg> 
                Κατηγορία
              </h6>  
              <p>              
                {{results[0].category}}
              </p>
              <h6 class="t-details-color font-size-xs style-italic">
                <svg class="ingr_details_icon"><use href="@/assets/icon_sprite.svg#spoon"/></svg>  
                Μετρήσεις
              </h6>
              <p>              
                <span class="unit" v-for="ingredient_unit in results[0].units" :key="ingredient_unit" >
                  {{ingredient_unit}}
                </span>
              </p>
             
              <table v-if="results[0].grammar" class="grammar">
                <tr>
                  <th class="t-details-color font-size-xs style-italic">Ενικός</th>
                  <th class="t-details-color font-size-xs style-italic">Πληθυντικός</th>
                </tr>
                <tr>
                  <td>
                    <p v-if="!grammar_isEditing">{{results[0].grammar.singular}}</p>
                    <input v-if="grammar_isEditing" v-model="results[0].grammar.singular" type="text" name="grammar_singular" class="text_input full_width_input" >
                  </td>
                  <td>
                    <p v-if="!grammar_isEditing">{{results[0].grammar.plural}}</p>
                    <input v-if="grammar_isEditing" v-model="results[0].grammar.plural" type="text" name="grammar_plural" class="text_input full_width_input" >
                  </td>
                </tr>
              </table>

               <div class="img_btn">
                <EditButton
                  v-if="user.data && (user.data.profile.admin || user.data.profile.editor)"
                    :isEditing="grammar_isEditing"
                    :I_am_the_author="I_am_the_author"
                    align="left"
                    text="γραμματικής"
                    border="no-border"
                    v-on:edit-pressed="grammar_isEditing = true"
                    v-on:save-pressed="updateIngredientData().then(data=>{grammar_isEditing = false})"
                  />
                </div>

            </div>
          </div>
          <div class="col-md-12">
            <p v-if="results[0].description && !description_isEditing" v-html="this.md(results[0].description)">
            </p>
            <div v-if="description_isEditing">
              <p class="subtext"> {{ $t('subtexts.markdown') }}</p>
              <!-- <input v-model="results[0].description" type="text" name="description" class="text_input name_input" placeholder="Λίγα λόγια για το υλικό..." > -->
              <textarea rows="5" v-model="results[0].description" name="description" id="description" class="text_input full_width_input" placeholder="Λίγα λόγια για το υλικό..."/>

            </div>

            <div class="img_btn">
                <EditButton
                  v-if="user.data && (user.data.profile.admin || user.data.profile.editor)"
                    :isEditing="description_isEditing"
                    :I_am_the_author="I_am_the_author"
                    align="right"
                    text="περιγραφής"
                    border="no-border"
                    v-on:edit-pressed="description_isEditing = true"
                    v-on:save-pressed="updateIngredientData().then(data=>{description_isEditing = false})"
                  />
            </div>
          </div>
        </div>
        <div class="recipes_section">
          <h3>Συνταγές με {{results[0].name}} </h3>
          <div class="recipes_cards row no-gutters">
            <div class=" col-md-4" v-for="recipe in recipies_with" :key="recipe.id" >
                <RecipeCard v-bind:result="recipe"/>
            </div>
          </div>
        </div>

        <IngredientQuestion :ingredient_id="results[0].name" v-if="user.data" />
        <!-- <IngredientQuestion :ingredients="ingr_List" v-if="user.data" /> -->

      </div>
      <div v-else> 
          <h1> Το υλικό δεν βρέθηκε</h1>
          <button @click="$router.go(-1)" class="primary_btn">> Πίσω</button>

      </div>
</template>

<script>
import RecipeCard from './extras/Recipe_card.vue';
import EditButton from './extras/Edit_button.vue';
import IngredientQuestion from './extras/Ingredient_question.vue';

import { mapGetters } from "vuex";

export default {
    name: 'IngredientInternal',
    components: {
      RecipeCard,
      EditButton,
      IngredientQuestion

    },
    data(){
        return {
            results:[],

            recipies_with:[],
            error:false,
            image:"",

            img_isEditing:false,
            description_isEditing:false,
            grammar_isEditing:false,

            I_am_the_author:false,

        }
    },
    computed: {
    // map `this.user` to `this.$store.getters.user`
      ...mapGetters({
        user: "user"
      }),
      ingr_List() {
        return [this.results[0]];
      }
    },
    watch: {
      '$route.params.name': function () {
        this.getingredient();
      },
      "$store.getters.user.data": function () {
          if(this.user.data.profile && (this.user.data.profile.admin || this.user.data.profile.editor)){
            this.I_am_the_author = true;
          }
        }
    },

    mounted(){
      this.getingredient();
      this.getRecipesWithIngredient();
      if(this.user.data.profile && (this.user.data.profile.admin ||this.user.data.profile.editor)){
        this.I_am_the_author = true;
      }
    },
    methods:{
      getRecipesWithIngredient: function(){
        fetch(process.env.VUE_APP_API+`/search/ingredients?ingredients=`+this.$route.params.name)
                .then(res => res.json())
                .then(res => {
                    this.searching = false;
                    if(res.error){
                        this.error = res.error;
                    }else{
                        if(res.length < 1){
                            this.error = "No recipes";
                        }else{
                            this.error = false;
                            this.recipies_with = res;
                        }
                    } 
                });
      },
      getingredient: function() {
        this.results = [];
        fetch(process.env.VUE_APP_API+`/ingredients/getByName/`+this.$route.params.name)
          .then(res => res.json())
          .then(res => {
            if(res.error){
              this.error = res.error;
            }else{
              if(res.length < 1){
                this.error = "No results";
              }else{
                this.results = res;
            }
            }
          });
      },
      handleFileChange(){
        this.image = this.$refs.image.files[0];
      },
      updateIngredientData() {
        return new Promise((resolve,reject)=>{
          var ingredient_form = new FormData();
          
          ingredient_form.append('image',this.image);
          ingredient_form.append('ingredient', JSON.stringify(this.results[0]));
          fetch(process.env.VUE_APP_API+`/ingredients/update/`+this.results[0].name,{
              method: "post",
              headers: {
                  "TOKEN":JSON.stringify(this.user.data.token),
              },
              body: ingredient_form
          }).then(res => res.json())
            .then(res => {
                //TODO: Error Validation
                if(res.error){
                    this.errors = res.error
                }
                else {
                  this.results[0] = res.ingredient;
                  this.image="";
                }
                resolve();
            });
        });
      }
    }
    
}
</script>

<style scoped lang="scss">

.ingredient_container{
  margin:auto;
  text-align: left;
  max-width: 840px;
}
.ingredient_header{
  padding-bottom: 2rem;
  border-bottom: var(--line-dashed);
  margin-bottom: 2rem;
}
.central_img{
  min-height: 340px;
  border-radius: 0 0 10px 10px;
  box-shadow: -17px -7px 0px -7px var(--color-secondary);
  margin-bottom: 2rem;
}
.dark .central_img {
  background-color: var(--color-text-primary);
}
.first_info {
  margin: 0.2rem 0.8rem;
  /* text-align: left; */
}
.first_info h1 {
  margin-top:0.6rem;
}
/* .first_info p {
  font-size: 0.8rem;
  color:var(--color-grey-2);
} */
// .first_info p svg {
//   width: 1rem;
//   height: 1rem;
//   fill: var(--color-grey-2);
// }
.recipes_section {
  margin-bottom: 2rem;
}

.unit {
  padding: 0 1rem;
  border-right:1px solid var(--color-grey-3);
  &:last-child {
    border:none;
  }
}

.grammar {
  width: 100%;
}
.grammar th, .grammar td {
  padding-right: 1rem;
}

  @media (max-width: 576.98px) {
    .no_photo_txt {
      display: none;
    }
  }
</style>