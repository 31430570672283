<template>

    <div class="profile_container container">

        <div v-if="profile">        
            <div class="cover_photo" v-if="profile.cover_photo" :style="{ backgroundImage: 'url(' + profile.cover_photo + ')' }">
            </div>
            <div v-else>
              <div class="cover_photo no_photo_txt" v-if="user && user.loggedIn && user.data.profile && user.data.profile.email  == profile.email"> 
                      <a href="/edit-profile" class="primary_link "> Επεξεργασία εικόνας </a> 
    
              </div>
            </div>

            <div class="options" v-if="user && user.loggedIn && user.data.profile && user.data.profile.email  == profile.email">
                <a href="/edit-profile" class="options_btn "> 
                    <svg class="edit_btn"><use href="@/assets/icon_sprite.svg#edit_icon"/></svg>
                    <span>Επεξεργασία Προφίλ</span> 
                </a> 
                <a href="/edit-account" class="options_btn "> 
                    <svg class="edit_btn"><use href="@/assets/icon_sprite.svg#settings_icon"/></svg>
                    <span>Επεξεργασία Λογαριασμού</span> 
                </a> 
                <a @click="logout_warning = true" href="#" class="options_btn "> 
                    <svg class="logout_btn"><use href="@/assets/icon_sprite.svg#login_user_icon"/></svg>
                    <span class="logout_span" >Αποσύνδεση</span> 
                </a> 

                <ActionModal  v-if="logout_warning"
                    title="Αποσύνδεση"
                    description="Θέλεις σίγουρα να αποσυνδεθείς;"
                    action_btn="Αποσύνδεση"
                    back_btn= "Ακύρωση"
                    btn_type="warning_btn"
                    v-on:accept-action="logout"
                    v-on:decline-action="logout_warning = false"
                />
            </div>

            <div class="profile-first-info">
              <div class="profile_photo" v-if="profile.profile_photo" :style="{ backgroundImage: 'url(' + profile.profile_photo + ')' }" > 
              </div>
              <div v-else>
                <div class="profile_photo no_photo_txt" v-if="user && user.loggedIn && user.data.profile && user.data.profile.email  == profile.email"> 
                  <a href="/edit-profile" class="primary_link "> Επεξεργασία <br>εικόνας </a> 
                </div>
              </div>
              <div class="user_displayName">
                <h3> {{profile.username}} </h3> 
              </div>
            </div>

            <hr class="solid-border-top visually-hidden-sm" v-if="user && user.loggedIn && user.data.profile && user.data.profile.email  == profile.email"/>

            <div class="options--mobile" v-if="user && user.loggedIn && user.data.profile && user.data.profile.email  == profile.email">
              <div class="options--mobile__content">
                <a href="/edit-profile" class="options_btn "> 
                    Επεξεργασία Προφίλ 
                </a> 
                <a href="/edit-account" class="options_btn "> 
                    Επεξεργασία Λογαριασμού 
                </a> 
                <a @click="logout_warning = true" href="#" class="options_btn "> 
                    Αποσύνδεση
                </a>
                <ActionModal  v-if="logout_warning"
                  title="Αποσύνδεση"
                  description="Θέλεις σίγουρα να αποσυνδεθείς;"
                  action_btn="Αποσύνδεση"
                  back_btn= "Ακύρωση"
                  btn_type="warning_btn"
                  v-on:accept-action="logout"
                  v-on:decline-action="logout_warning = false"
                />
              </div> 
            </div> 
            <hr class="solid-border-top visually-hidden-sm"/>

            <div class="about_section " v-if="profile.description">
                <h4>Προφίλ</h4>
                <p v-html="this.md(profile.description)"></p>
            </div>

            <!-- <div class="details_section container">
                <div class="user_details row ">
                    <div class="col">
                        <span>Συνταγές</span>
                        <svg class="details_icons"><use href="@/assets/icon_sprite.svg#time_icon"/></svg>   
                        <span class="big_numbers">{{results.length}}</span>
                    </div>
                    <div class="col">
                        <span>Ιδιωτικές Συνταγές</span>
                        <svg class="details_icons"><use href="@/assets/icon_sprite.svg#time_icon"/></svg>   
                        <span class="big_numbers">3</span>
                    </div>
                    <div class="col">
                        <span>Αγαπημένες Συνταγές</span>
                        <svg class="details_icons"><use href="@/assets/icon_sprite.svg#time_icon"/></svg>   
                        <span class="big_numbers">4</span>
                    </div>
                    <div class="col">
                        <span>Φήμη</span>
                        <svg class="details_icons"><use href="@/assets/icon_sprite.svg#time_icon"/></svg>   
                        <span class="big_numbers">3</span>
                    </div>

                </div>
            </div>  -->

             <div class="recipes_section">
                <h4> Συνταγές</h4>
                <div class="recipies_cards row no-gutters" v-if="results.length > 0">
                    <div v-for="(result,index) in results" :key="result.id" class=" col-md-4">
                        <RecipeCard v-bind:result="result" v-if="index < total_recipies"/>
                    </div>
                     <button 
                        class="primary_btn"
                        @click="total_recipies += load_more_step"
                        v-if="results && total_recipies < results.length">
                        Φόρτωση Περισσότερων
                    </button>
                </div>
                <div v-else>
                    <div v-if="user && user.loggedIn && user.data.profile  == profile"> 
                    <p class="style-italic t-details-color">Δεν έχεις ανεβάσει καμία συνταγή. Κάνε την αρχή! </p>
                    <router-link to="/recipe-form" class="primary_btn add_recipe">Νέα Συνταγή</router-link>
                    </div>
                    <div v-else>
                    <p class="style-italic t-details-color">Δεν υπάρχουν συνταγές. </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "../../store.js";

import RecipeCard from './Recipe_card.vue';
import ActionModal from './Action_modal.vue';


export default {

    name:'Profile',
    components: {
      RecipeCard,
      ActionModal
    },
    props: [ 
        'profile',
        'results'
    ],
    data(){
        return {
            logout_warning:false,
            total_recipies:9,
            load_more_step:6
        }
    },
    computed: {
        ...mapGetters({
            user: "user"
        })
    },
    methods: {
        logout:function(){
            store.dispatch("logout");
            this.$router.push({ path: '/'})
        },
    }
    
}
</script>

<style scoped>

.container {
    max-width: var(--max-width-container);
}

.cover_photo{
  background-color: var(--color-grey-3);
  min-height: 250px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-size: cover;
  background-position: center;
  position: relative;
  top: -1rem;
  margin-bottom: -1rem;
  
}
.cover_photo img {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.profile_photo {
  background-color: #dedede;
  border: 6px solid white;
  border-radius: 100px;
  width: 160px;
  height: 160px;
  box-shadow: 0 8px 20px #00000030;
  position: relative;
  top: -30px;
  margin-bottom: 10px;
  margin-left: 1rem;
  background-size: cover;
  background-position: center;
}



.no_photo_txt {
  display: flex;
  align-items: center;
  justify-content: center;
}
.user_displayName {
  text-align: left;
  width: fit-content;
  margin-top: 0.6rem;
  margin-left: 2rem;
  height: fit-content;}
h3 {
  font-weight: 700;
  text-align: center;
}
.profile-first-info {
  display: flex;
}
@media (max-width: 767.98px) {
  .user_displayName {
    margin-top: 2rem;
  }
}


.options {
  display: flex;
  float: right;
}

.options_btn {
  display: flex;
}

.options span {
  display: none;
  position: absolute;
  width: 0;
  margin-top: 3rem;
  margin-left: -1.5rem;
  text-align: center !important;

  opacity: 0;
  color: var(--color-text-primary);
}
.dark .options span {
  color: var(--color-text-primary-dark) ;
}
.logout_span {
  margin-left: -2.5rem !important;
}
.options svg {
  height: 2.6rem;
  width: 2.6rem;
  fill:var(--color-grey-1);
  padding: 0.8rem;
  transition: 0.1s all ease;
}
.dark .edit_btn, .dark .logout_btn {
  border-bottom: 2px solid  var(--color-bg-primary-dark);
}
.edit_btn, .logout_btn {
  border-bottom: 2px solid white;
}

.edit_btn:hover {
  border-bottom: 2px solid var(--color-secondary);
  fill:var(--color-secondary);
}
.options_btn:hover span {
  display: block;
  opacity: 1;
}

.logout_btn:hover {
  border-bottom: 2px solid var(--color-red);
  fill:var( --color-red);
}


.about_section{
  text-align: left;
  margin-bottom: 2rem;
}
.user_details {
  margin-bottom: 2rem;
  margin-top: 2rem;
  padding: 1rem;
  text-align: center;
  border-top: var(--line-dashed);
  border-bottom: var(--line-dashed);
}
.user_details div {
  display: grid;
  padding: 0;
}
.user_details span {
    min-height: 2rem;
}
.big_numbers {
  font-size: 2rem;
  font-weight: 100;
  margin-top: 0.6rem;
}
/* .details_section {
  display: none;
} */
.details_icons {
  margin: 1rem auto 0;
  fill: #f7f4f4;
  height: 4rem;
  width: 4rem;
  z-index: -1;
  position: relative;
  top: -1rem;
  margin-bottom: -7rem;
}

.recipes_section {
    text-align: left;
}
.recipes_section h4 {
  margin-bottom: 2rem;
}
h4 {
  text-align: center;
}
.recipes_cards{
    align-items: baseline;
} 

  .options--mobile {
    display: none;
  }

/* MOBILE VIEW */
 @media (max-width: 575.98px) {
  .profile-first-info {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .profile_photo {
    top: -70px;
    margin:0 0 -50px;
  }
  .user_displayName {
    margin: 0;
  }
  .solid-border-top {
    padding-top: 0 !important;
  }

  .options--mobile {
    display: block;
    overflow-x: scroll;
    width: 100%;
  }
  .options--mobile__content {
    display: flex;
    grid-gap: 1rem;
    width: max-content;
  }
  .options--mobile .options_btn {
    border-radius: 20px;
    border: 1px solid var(--color-primary);
    padding: 2px 16px;
  }

  .options {
    display: none; 
  }
    /* 
  .options svg {
    display: none;
  }
  .options span {
    display: block;
    border: 1px solid var(--color-primary);
    border-radius: 20px;
  } */

}

</style>
