<template>
        <ul class="navigation">
            <li>
                <router-link :to="{ name: 'Search'}">Συνταγές</router-link>
            </li>
            <li>
                <router-link to="/recipe-form">Νέα Συνταγή</router-link>
            </li>
            <!-- <li>
                <router-link to="/contactus">Επικοινωνία</router-link>
            </li> -->
            <li class="user_li">
                <router-link to="/login" v-if="user && !user.loggedIn">Σύνδεση / Εγγραφή</router-link>
                <router-link :to="'/user'" v-if="user && user.loggedIn">
                    <div class=loggedin_user>
                        <div class="profile_photo" v-if="user.data.profile && user.data.profile.profile_photo" :style="{ backgroundImage: 'url(' + user.data.profile.profile_photo + ')' }" > 
                        </div>
                        {{user.data.displayName}}
                    </div>
                </router-link>
            <!-- </li>
            <li > -->
                <div class="newsfeed_div" v-if="user && user.loggedIn">
                    <svg class="icon" v-bind:class="{ new_news_icon: news_flag}" @click="openNews()"><use href="@/assets/icon_sprite.svg#bell"/></svg>
                    <div class="dot"  v-bind:class="{ new_news: news_flag}"></div>
                    <ul class="newsfeed_list" v-if="show_news">
                        <li v-for="(news, index) in user.data.profile.newsfeed" :key="index" v-bind:class="{ read : news.read}" @click="news.read = true;GotobyNews(news.recipe_id)" >
                            {{news.recipe_id}}: <br>
                            {{news.text}}
                        </li>
                    </ul>
                    
                </div>
            </li>

        </ul>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: 'Navigation',
    data(){
        return {  
            profile: {},
            news_flag:false,
            show_news:false,

          }
    },
    computed: {
        // map `this.user` to `this.$store.getters.user`
        ...mapGetters({
            user: "user"
        })
    },
    mounted() {
    },
    
    methods: {
        checkNews() {
            this.news_flag=false;
            for (let one_new of this.user.data.profile.newsfeed) {
                if (!one_new.read) {
                this.news_flag=true;
                    break;
                }
            }
        },
        GotobyNews:function(event){
            this.checkNews();
            this.$router.push({ path: '/recipes/'+event})
            this.show_news=false;
        },
        openNews() {
            if (this.show_news) {
                this.news_flag=false;
            }
            this.show_news=!this.show_news;
        }
    }
}
</script>

<style scoped lang="scss">
.user_li {
    border: 1px solid var(--color-grey-3);
    padding: 0.2rem 0.6rem 0.1rem;
    border-radius: 50px;
    display: flex;
    align-items: center;
    transition: border 0.6s ease;
}
.dark .user_li {
    border: 1px solid var(--color-text-primary);
}
.loggedin_user {
    display: inline-flex;
    align-items: center;
}
.profile_photo{
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-size: cover;
    margin-right: 0.6rem;

}
.newsfeed_div {
    height: 0.8rem;
    padding: 0 0.4rem 0 0.8rem;
    display: none;
}
.icon {
     display: block;
    width: 0.8rem;
    height: 0.8rem;
    fill:var(--color-darker-blue);
    opacity:0.4;
    transition: all 0.2s;
    &:hover{
        fill:var(--color-secondary);
        transform: rotate(13deg);
    }
}
.new_news_icon {
    opacity:1;
}
.dark .icon {
    fill: white;
    &:hover{
        fill:var(--color-primary);
    }
}
.dot {
    width: 8px;
    height: 8px;
    border-radius: 6px;
    background-color: transparent;
    position: relative;
    top: -1.2rem;
    right: -0.8rem;
}
.dot.new_news {
    background-color: var(--color-red);
}

.newsfeed_list {
    font-size: 0.8rem;
    border: var(--line);
    list-style: none;
    padding: 0;
    text-align: right;
    position: absolute;
    width: 260px;
    right: 1rem;
    margin-top: 1rem;
    border-radius: 6px;
    background-color: white;
    z-index: 1;
}
.dark .newsfeed_list {
    background-color: var(--color-bg-primary-dark);

}
.newsfeed_list li {
    padding: 0.5rem;
    margin: 0;
    cursor: pointer;
    transition: color 0.2s;
    transition-delay: 0.6s;
    /* background-color: #0000000f; */
    
}
.newsfeed_list li:hover {
    background-color: white;
}
.dark .newsfeed_list li:hover {
    background-color: var(--color-text-primary);
}
/* .unread {
    background-color: var(--color-grey-2);
} */
.read {
    color: var(--color-grey-1);
        background-color: #0000000f;

}

</style>    