<!--
this components is used when we need an action or a warning modal.
we call it like this:
<ActionModal v-if="show..."
    title="..."
    description="..."
    action_btn="..."
    back_btn= "..."
    btn_type="warning_btn || action_btn"
    v-on:accept-action="..."
    v-on:decline-action="show... = false"
/>
-->
<template>
    <div class="popup">
        <div class="logout_warning">
        <h4>{{title}}</h4>
        <p>{{description}}</p>
        <button type="button" class="primary_btn decline_btn" @click="$emit('decline-action')" v-if="back_btn">{{back_btn}} </button>
        <button type="button" class="primary_btn " :class="btn_type" @click="$emit('accept-action')">{{action_btn}} </button>
        </div>
    </div>

</template>

<script>
export default {
    name:"ActionModal",
    props: [ 
        'title',
        'description',
        'action_btn',
        'back_btn',
        'btn_type'
    ],

}
</script>

<style scoped>


/* DARK */

.dark .logout_warning {
    background-color: var(--color-bg-primary-dark);
    color: var(--color-text-primary-dark)

}

.popup {
    position: fixed;
    left: 0;
    top: 0rem;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.6);
}

.logout_warning {
    position: relative;
    max-width: 500px;
    min-width: 260px;
    top: 20%;
    margin: 1rem auto;
    padding: 2rem 3rem;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 0 70px #000000a6;
}

.primary_btn {
  margin: 1rem;
  display: inline-block;
  width: 40%;
}
 @media (max-width: 575.98px) {
   .logout_warning {
     margin: 0.8rem;
   }
   .primary_btn {
     width: fit-content;
   }
 }
.decline_btn {
    background-color: unset;
    color:var(--color-primary);
}
.decline_btn:hover {
    background-color: unset;
    color:var(--color-secondary);
}
h4, p {
  margin-bottom: 2rem;
text-align: center !important;

}




</style>