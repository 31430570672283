<template>
  <div class="full">
    <div class="d-block d-sm-none">
      <div
        class="mobile-close"
        v-on:click="mobileShowSearch = !mobileShowSearch"
        v-bind:class="{ 'd-none': !mobileShowSearch }"
      >
        Κλεισιμο
        <svg class="">
          <use href="@/assets/icon_sprite.svg#x_icon" />
        </svg>
      </div>
    </div>
    <div class="container container-xl container-flex">
      <div class="d-block d-sm-none">
        <button
          class="primary_btn"
          v-on:click="mobileShowSearch = !mobileShowSearch"
          v-bind:class="{ 'd-none': mobileShowSearch }"
        >
          <svg class="">
            <use href="@/assets/icon_sprite.svg#search_icon" />
          </svg>
          {{ $t('search_section.button_value') }}
        </button>
      </div>
      <div
        class="search_section_advanced d-sm-block"
        v-bind:class="{ 'd-none': !mobileShowSearch }"
      >
        <h1>
          <span>{{ $t('search_section.title') }}</span>
        </h1>
          <div class="row">
            <div class="col col-xs-12 filter_element">
              <label >Αναζήτησε συστατικό, σκεύος, όνομα συνταγής ή ετικέτα</label>
              <vue-tags-input
                v-model="tag"
                :autocomplete-items="filteredItems"
                :tags="tags"
                @tags-changed="tagsChanged"
              />
            </div>
          </div>
          <div class="sliders">
            <div class="slider">
              <svg class="">
                <use href="@/assets/icon_sprite.svg#cooking_time" />
              </svg>
              <p>Χρόνος μαγειρέματος</p>
              <vue-slider
                v-model="cooking_time"
                :min="0"
                :max="600"
                :interval="1"
                :tooltip="'always'"
                :tooltip-formatter="formatter_time"
              ></vue-slider>
            </div>
            <div class="slider">
              <svg class="">
                <use href="@/assets/icon_sprite.svg#waiting_time" />
              </svg>
              <p>Χρόνος αναμονής</p>
              <vue-slider
                v-model="waiting_time"
                :min="0"
                :max="600"
                :interval="1"
                :tooltip="'always'"
                :tooltip-formatter="formatter_time"
              ></vue-slider>
            </div>
            <div class="slider">
              <svg class="">
                <use href="@/assets/icon_sprite.svg#portions" />
              </svg>
              <p>Μερίδες</p>
              <vue-slider
                v-model="portions"
                :min="1"
                :max="50"
                :adsorb="true"
                :interval="1"
                :tooltip="'always'"
              ></vue-slider>
            </div>
            <div class="slider">
              <svg class="">
                <use href="@/assets/icon_sprite.svg#difficulty" />
              </svg>
              <p>Δυσκολία</p>
              <vue-slider
                v-model="difficulty"
                :min="1"
                :max="3"
                :adsorb="true"
                :interval="1"
                :tooltip="'always'"
              ></vue-slider>
            </div>
            <div class="slider">
              <svg class="">
                <use href="@/assets/icon_sprite.svg#star_icon" />
              </svg>
              <p>Βαθμολογία</p>
              <vue-slider
                v-model="rating"
                :min="0"
                :max="5"
                :adsorb="true"
                :interval="1"
                :tooltip="'always'"
              ></vue-slider>
            </div>
          </div>
          <button class="primary_btn" @click="search">
            <svg class="">
              <use href="@/assets/icon_sprite.svg#search_icon" />
            </svg>
            {{ $t('search_section.button_value') }}
          </button>
      </div>

      <ingredientChips
        v-if="selectedIngredients && selectedIngredients.length>0"
        slider=true
        :ingredients="selectedIngredients"
      /> 
      <div class="recipes_section">
        <h1> Συνταγές</h1>

        <span v-if="error">{{ this.error }}</span>
        <div class="recipes_cards row no-gutters">
          <div
            v-for="result in get_AND_Recipies"
            :key="result.id"
            class="col-lg-3 col-md-4 col-sm-6"
          >
            <RecipeCard v-bind:result="result" />
          </div>

        </div>
        <button 
            class="secondary_btn"
            @click="total_AND_recipies += load_more_step"
            v-if="results.AND_match && total_AND_recipies < results.AND_match.length">
            Φόρτωση Περισσότερων
        </button>
        <button 
            class="secondary_btn"
            @click="total_AND_recipies += load_more_step" 
            v-if="!results.AND_match && total_AND_recipies < results.length">
            Φόρτωση Περισσότερων
        </button>

        <h5 v-if="results.OR_match && results.OR_match.length>0" class="style-italic t-details-color">Αποτελέσματα που έχουν τουλάχιστον ένα συστατικό από αυτά που επιλέγατε</h5>

        <!-- <span v-if="error">{{ this.error }}</span> -->

        <div class="recipes_cards row no-gutters">
          <div
            v-for="result in get_OR_Recipies"
            :key="result.id"
            class="col-lg-3 col-md-4 col-sm-6"
          >
            <RecipeCard v-bind:result="result" />
          </div>
          
        </div>
        <button 
            class="secondary_btn"
            @click="total_OR_recipies += load_more_step" 
            v-if="results.OR_match && total_OR_recipies < results.OR_match.length">
            Φόρτωση Περισσότερων
        </button>

      </div>
    </div>

    <!-- <Top_5_recipes/> -->
  </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';
import RecipeCard from './extras/Recipe_card.vue';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import IngredientChips from './extras/IngredientChips.vue'
var stringSimilarity = require("string-similarity");

// import Top_5_recipes from './Top_5_recipes.vue';
export default {
  components: {
    VueTagsInput,
    RecipeCard,
    VueSlider,
    //Top_5_recipes
    IngredientChips,

  },
  name: 'Search',
  data() {
    return {
      tag: '',
      tagUtensil:'',
      tagRecipeTag:'',
      tags: [],
      tagsUtensil:[],
      tagsRecipeTag:[],
      results: [],
      error: false,
      searching: false,
      autocompleteItems: [],
      waiting_time: [0, 600],
      cooking_time: [0, 600],
      difficulty: [1, 3],
      rating: [0, 5],
      portions: [1, 50],
      formatter_time: "{value}'",
      mobileShowSearch: false,
      total_AND_recipies:16,
      total_OR_recipies:16,
      load_more_step:8
      
    };
  },
  beforeMount() {
    this.getRecipes();
    this.getElements();
  },
  props: {},
  computed: {
    selectedIngredients() {
      return this.tags.filter(i =>{
        return i.type == 'ingredient'
      })
    },
    filteredItems() {
      return this.filterItemsByTitleAndSimilarity(this.tag, 0.4)
    },
    get_AND_Recipies() {
      if (this.results.AND_match) {
        if (this.total_AND_recipies < this.results.AND_match.length) {
          return this.results.AND_match.slice(0,this.total_AND_recipies);
        } else {
          return this.results.AND_match;
        }
      }else {
        if (this.total_AND_recipies < this.results.length) {
          return this.results.slice(0,this.total_AND_recipies);
        } else {
          return this.results;
        }
      }
    },
    get_OR_Recipies() {
      if (this.results.OR_match) {
        if (this.total_OR_recipies < this.results.OR_match.length) {
          return this.results.OR_match.slice(0,this.total_OR_recipies);
        } else {
          return this.results.OR_match;
        }
      }
      else { return 0; }
    }
  },
  methods: {
    filterItemsByTitleAndSimilarity: function(a_string_value,low_limit){
      return this.autocompleteItems.filter(i => {
        if(i.value.toLowerCase().indexOf(a_string_value.toLowerCase()) == -1){
          if(stringSimilarity.compareTwoStrings(i.value.toLowerCase(),a_string_value.toLowerCase()) >= low_limit){
            return true;
          }else{
            return false;
          }
        }else{
          return true;
        }
      });
    },
    tagsChanged: function(newTags){
      newTags = newTags.map((tag)=>{
        if(!tag.type){
          let rich_data_object = this.autocompleteItems.filter(i => {
            return i.value == tag.text
          })
         
          if(rich_data_object.length > 0){
            return rich_data_object[0]; 
          }else{
            return tag;
          }
        }else{
          return tag;
        }
      })
      this.tags = newTags;
      this.search()
    },
    getElements: function() {
      fetch(process.env.VUE_APP_API + `/elements/getall`)
        .then(res => res.json())
        .then(res => {
          this.autocompleteItems = [];
          for(let ingredient of res.ingredients){
            this.autocompleteItems.push({ ...ingredient, value:ingredient.name, text: ingredient.name+" [συστατικό]", type:"ingredient" })
          }
          for(let tag of res.tags){
            let check_exists = this.autocompleteItems.find((check_entry)=>{
              return check_entry.value == tag
            })
            if(!check_exists){
              this.autocompleteItems.push({value:tag, text: tag+" [ετικέτα]", type:"tag" })
            }
          }
          for(let utensil of res.utensils){
            let check_exists = this.autocompleteItems.find((check_entry)=>{
              return check_entry.value == utensil
            })
            if(!check_exists){
              this.autocompleteItems.push({value:utensil, text: utensil+" [σκεύος]", type:"utensil" })
            }
          }
          for(let title of res.recipeTitles){
            let check_exists = this.autocompleteItems.find((check_entry)=>{
              return check_entry.value == title
            })
            if(!check_exists){
              this.autocompleteItems.push({value:title, text: title+" [τίτλος]", type:"title" })
            }
          }
        });
    },
    search: function() {
      this.searching = true;
      this.results = [];
      let elements = [];
      for (let tag of this.tags) {
        elements.push(tag.value || tag.text)
      }

      let options = { 
        method: 'POST', 
        headers:{
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          elements: elements,
          cooking_time: this.cooking_time,
          waiting_time: this.waiting_time,
          portions: this.portions,
          difficulty: this.difficulty,
          rating: this.rating,
        })
        }
      fetch(process.env.VUE_APP_API+ `/search`, options)
        .then(res => res.json())
        .then(res => {
          this.searching = false;
          if (res.error) {
            this.error = res.error;
            this.getRecipes();
          } else {
            if (res.length < 1) {
              this.error = 'No results';
            } else {
              this.error = false;
              this.results = res;
            }
          }
        });
    },
    getRecipes: function() {
      this.results = [];
      fetch(process.env.VUE_APP_API + `/recipes/getall`)
        .then(res => res.json())
        .then(res => {
          if (res.error) {
            this.error = res.error;
          } else {
            if (res.length < 1) {
              this.error = 'No results';
            } else {
              this.results = res;
              this.results = this.results.map((entry)=>{
                if(entry.central_media){
                  let image_path = entry.central_media.split("https://cookingapi.handbook.gr")[1]
                  entry.central_media = process.env.VUE_APP_API + image_path
                }
                return entry
              })
            }
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: 1rem;
  margin-top: 2rem;
}
.row {
  align-items: flex-end;
}
label {
  margin: 0.5rem;
  display: block;
  text-align: left;
}
.vue-tags-input {
  display: block;
  margin: 0.5rem;
  max-width: unset !important;
}
.submit_filters {
  padding: 0.5rem;
}

.search_section {
  /* background: url(require('@/public/food-bg.jpg')); */
  background-color: var(--color-grey-1);
  padding: 0.5rem 0;
}

.dark .search_section {
  background-color: var(--color-text-primary);
}

.recipes_cards {
  /* display: flex; */
  /* justify-content: space-between; */
  text-align: left;
  align-items: baseline;
}
.recipes_section h5 {
  text-align: center;
  margin: 2rem auto;
}
</style>
