<template>
  <!-- <div id="app" :class="(mode && mode =='dark') ? 'dark' : '' " v-on:change_mode="changeMode()"> -->
  <div id="app" :class="mode">
    <TopHeader class="top_header" v-on:change-mode="changeMode" />
    <Header class="header" />

    <router-view class="main_content"></router-view>
    <cookie-consent 
    message="Τα cookies είναι σημαντικά για το cooking handbook καθώς με αυτό τον τρόπο δίνεται η δυνατότητα στον ιστότοπο να συγκεντρώνει πληροφορίες, οι οποίες μπορούν να διευκολύνουν την επόμενη επίσκεψή σας και να κάνουν τον ιστότοπο πιο χρήσιμο για εσάς."  
    linkLabel="Μάθετε περισσότερα"
    buttonLabel="Αποδοχή"
    />

    <Footer />
    <ProblemSign />
  </div>
</template>

<script>
import TopHeader from './components/Top_header.vue';
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import ProblemSign from './components/extras/Problem.vue';

export default {
  name: 'App',
  components: {
    TopHeader,
    Header,
    Footer,
    ProblemSign
  },
  data() {
    return {
      mode: '',
    };
  },
  beforeMount() {
    this.initMode();
  },
  methods: {
    initMode: function() {
      if (!localStorage.mode) {
        this.mode = 'light';
      } else if (localStorage.mode == 'dark') {
        this.mode = 'dark';
      }
    },
    changeMode: function(mode) {
      console.log(mode);
      this.mode = mode;
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');
@import './assets/styles/app.scss';
</style>
