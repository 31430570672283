<template>
    <div>
      <h1>Γίνε μέρος της ομάδας!</h1>
      
      <div class="small_container">

       <ul>
          <li>
              <router-link to="/login" class="inactive_tab tab">Σύνδεση</router-link>
          </li>
          <li >
              <router-link to="/signup" class="active_tab tab">Εγγραφή</router-link>
          </li>
        </ul>

        <SocialLoginButtons/>

        <div class="solid-border-top">
          <span class="or"> ή </span>
        </div>

        <div action="" class="myForm">
          <label for="username">Username χρήστη:</label>
          <input v-model="form.username" type="text" name="username" id="username" class="text_input">
          <label for="mail">Email χρήστη:</label>
          <input v-model="form.email" type="email" name="mail" id="mail" class="text_input">
          <label for="password1">Κωδικός:</label>
          <input v-model="form.password" type="password" name="password1" id="password1" class="text_input">
          <label for="password2">Επιβεβαίωση κωδικού:</label>
          <input v-model="form.password2" type="password" name="password2" id="password2" class="text_input">
          <input  @click="submit" type="submit" value="Εγγραφή" class="primary_btn form_btn">

        </div>

        <div class="alert alert-danger" v-if="error">{{error}}</div>
      </div>
        
    </div>
</template>

<script>
import firebase from "firebase";
import SocialLoginButtons from "./extras/Social_login_btns.vue";


export default {
  components: {
    SocialLoginButtons
  },
  name: 'Signup',
  data(){
    return {
      form:{
        username:"",
        email:"",
        password:"",
        password2:""
      },
      error:false
    }
  },
  methods:{


    submit() {
      if (this.form.password == this.form.password2) {
        firebase
          .auth()
          .createUserWithEmailAndPassword(this.form.email, this.form.password)
          .then(data => {
            data.user
              .updateProfile({
                displayName: this.form.username
              })
              .then(() => {
                // this.$router.replace({ name: "Login" });
                if(this.$route.query.fwd){
                  this.$router.push(this.$route.query.fwd)
                }else{
                  this.$router.replace({ name: "Search" });
                }
              });
          })
          .catch(err => {
            this.error = err.message;
          });    
      }else {
        this.error = "Η επιβεβαίωση κωδικού απέτυχε";
      }

    }
  }
}
</script>

<style scoped>

h1 {
  margin-bottom: 4rem;
}
ul {
  list-style: none;
    display: inline-flex;
    padding: 0;
    max-width: 400px;
    width: 100%;
    justify-content: center;
    border-bottom: 3px solid var(--color-text-primary);
}
 li {
   width: 50%;
 }

</style>
