import { render, staticRenderFns } from "./About_us.vue?vue&type=template&id=3603b21f&"
import script from "./About_us.vue?vue&type=script&lang=js&"
export * from "./About_us.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports