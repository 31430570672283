<template>
    <div class="home">
        <HeroSection/>
        <RecipesSection />
        <IngredientSection />
        <JoinusSection :user="user"/>
        <AboutSection />

    </div>
</template>

<script>
    
    import { mapGetters } from "vuex";
    import HeroSection from './home/hero_section.vue';
    import JoinusSection from './home/Joinus_section.vue';
    import RecipesSection from './home/Recipes_section.vue';
    import AboutSection from './home/About_section.vue';
    import IngredientSection from './home/Ingredient_section.vue';

    export default {
        name: 'Home',
        data(){
            return {  
               
            }
        },
        components: {
            HeroSection,
            JoinusSection,
            RecipesSection,
            AboutSection,
            IngredientSection,
        },
        computed: {
            // map `this.user` to `this.$store.getters.user`
            ...mapGetters({
                user: "user"
            })
        },
        beforeMount() {
        },
        methods:{

        }
        
    }
</script>

<style lang="scss" scoped>

</style>