<template>
  <div >
  <div class="container">

    <div class="row">
      <div class="col-md-3">
        <a href="/"><img class="logo_img" src="@/assets/cooking_logo.svg" alt=""></a>
        <!-- ^^ gia na kanei refresh -->
      </div>


      <div class="col-md-9 row">
        <Navigation/>
      </div>

    </div>
  </div>
  </div>
</template>

<script>
import Navigation from './Navigation.vue'



export default {
  components: {
    Navigation
  },
  name: 'Header',


}
</script>

<style scoped>


.logo_img {
  height: 50px;
  width: 200px;
  margin: 0.5rem 0;
}

.col-md-9 {
  justify-content: flex-end;
}
@media (max-width: 767.98px) { 
  .col-md-9 {
    justify-content: center;
  }
}


</style>
