<!--
Use this component when you need a button to the previous page
Call it like this:
<BackButton 
    text="..." // optional 
/>
-->

<template>
  <button class="back_btn" type="button" @click="goBack()"> {{txt}} </button>
  
</template>

<script>
export default {
   name:"BackButton",
    props: [ 
        'text',
    ],
    data(){
        return {
            txt:this.text || "Πίσω",
        }
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      }
    }
}
</script>

<style lang="scss" scoped>

</style>