<template>
    <div class="utensil_form" >
        <div class="div-btn">
            <button type="button" @click="emitDeleteEvent" class="primary_btn del_btn"> - </button>
        </div>
        <div class="row">
            <div class="u_name col-sm-5">
                <input type="text" v-model="name" name="utensil_name" class="text_input" v-on:input="updateValueToParent()" v-bind:placeholder="rand_txt(this.random_number,'ut')" >
            </div>
            <div class="u_quantity col-sm-2">
                <input type="number" v-model="quantity" name="u_quantity" class="text_input" v-on:input="updateValueToParent()" v-bind:placeholder="rand_txt(this.random_number,'qu')" min="0">
            </div>
            <div class="u_details col-sm-5" >
                <input type="text" v-model="details" name="u_details" class="text_input" v-on:input="updateValueToParent()" v-bind:placeholder="rand_txt(this.random_number,'det')">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'NewUtensil',
    props: [ 'given_name','given_details','given_quantity' ],
    data(){
        return {
            quantity:this.$props.given_quantity || "",
            name:this.$props.given_name || "",
            details:this.$props.given_details || "",

            random_number:0,
        }
    },
    beforeMount(){
        // this.rand_txt();
        this.rand_num();

    },
    methods:{
        updateValueToParent: function () {
            this.$emit('input', { 
                quantity:this.quantity,
                name:this.name,
                details:this.details,
            })
        },
        emitDeleteEvent(){
            this.$emit("remove")
        },
        rand_num() {
            this.random_number = Math.floor(Math.random() * Math.floor(4));
        },
        rand_txt (n, a) {
            var random_placeholder=[
                {ut:"κουτάλι",  qu:1,   det:"μεγάλο"}, 
                {ut:"φόρμα",    qu:1,   det:"για κέικ"},
                {ut:"κατσαρόλα",qu:1,   det:"28 εκ."},
                {ut:"τάπερ(ς)", qu:3,   det:""}
            ];
            if (a=="ut") {
                return random_placeholder[n].ut;
            }
            else if (a=="qu") {
                return random_placeholder[n].qu;
            }
            else if (a=="det") {
                return random_placeholder[n].det;
            }
        }

    }
    
}
</script>

<style scoped>

.utensil_form {
   display: flex;
    justify-content: space-between;
    margin-top: 1.3rem;
    align-items: flex-start;

}

.utensil_form div {
    display: flex;
    flex-wrap: wrap;
}

.utensil_form div * {
    width: 100%;
}

.div-btn {
    margin-top: 1rem;
}
.row {
    width: 100%;
}

/* .u_quantity {
    width: 12%;
}
.u_name {
    width: 32%;
}
.u_details {
    width: 48%;
} */



</style>