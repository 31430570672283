<template>
    <span class="info-sign" v-if="ingredient_name" @click="isHidden=!isHidden" v-click-outside="onClickOutside">
         <span class="info-sign__title">{{ingredient_name}}</span>
        <div class="info-sign__tooltip" v-if="isHidden">
            <h5>{{ingredient_name}}</h5>
            <button class="details_btn info-sign__btn" @click="select_ingredient()" > Περισσότερα</button>
        </div>
    </span>
</template>


<script>
import vClickOutside from 'v-click-outside'
export default {
    name:'info-sign',
    directives: {
      clickOutside: vClickOutside.directive
    },
    props:[
        'mandatory',
        'ingredient_name'
    ],
    data() {
        return {
            isHidden:false

        }
    },
    methods: {
      select_ingredient:function(){
        this.$router.push({ path: '/ingredients/'+this.ingredient_name});
        //window.location.href="/recipe/"+ event.currentTarget.id
      },
      onClickOutside () {
        this.isHidden=false;
      }
    }
    
}
</script>

<style lang="scss" scoped>
  .info-sign{
    position: relative;
  }
  .info-sign__title{
    cursor: pointer;
    font-weight: 600;
    display: inline;
    &:hover {
      text-decoration: underline 2px;
    }
  }
  .info-sign__tooltip {
    position: absolute;
    bottom: 2rem;
    left: -2rem;
    z-index: 1;
    max-width: 400px;
    min-width: 200px;
    padding: 0.5rem;
    border-radius: 6px;
    box-shadow: 0 4px 10px #55555533;
    display:grid;
    transition: opacity 0.3s ease;
    background-color: rgba(var(--color-text-primary-rgb),0.8);
    color: var(--color-grey-3);
    backdrop-filter: blur(4px);
  }
  .dark .info-sign__tooltip {
    background-color: var(--color-text-primary);
    color: var(--color-grey-3);
  }
  .info-sign__btn {
    border: none;
    background: none;
    color:var(--color-primary) ;
    font-style: italic;
    font-size: 0.8rem;
    text-align: right;
    width: fit-content;
    justify-self: end;
    &:hover {
      color: var(--color-secondary);
    }
  }
  .info-sign__tooltip h5 {
    padding-bottom: 0.5rem;
    font-size: 0.9rem;
  }
</style>