<!--    
<IngredientQuestion 
    :ingredient_id="results.sintagi[0].ingredient.id" 
    :recipe_id = "results.sintagi[0].id"
/>
-->
<template>
    <div class="window" v-bind:class="{ active: isActive }">
        <svg class="close_btn" v-on:click="isActive=false"><use href="@/assets/icon_sprite.svg#x_icon"/></svg>   

        <p class="t-details-color font-size-sm style-italic">Βοηθήστε μας να μάθουμε περισσότερα. </p>
        <h5>{{ingredient}}</h5>
        <p>Σε ποια κατηγορία τροφίμων ανήκει;</p>

        <!-- <form action="/action_page.php"> -->
        <form >
            <select v-model="selected" class="text_input full_width_input">
                <option v-for="category in categories" v-bind:value="category.value" :key="category.value">
                    {{ category.text }}
                </option>
            </select>
            <input type="button" value="Υποβολή" class="primary_btn" @click="submitIngredientCategory">

        </form> 

    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {


    name: 'Ingredient-question',
    props: [
        'ingredient_id',
        'recipe_id'
    ],
    data(){
        return {
            isActive:false,
            ingredient:"",
            ingredient_list:[],
            categories:[
                {text: 'Ψωμί, δημητριακά & πατάτες',value: '0'},
                {text: 'Γάλα & γαλακτοκομικά',      value: '1'},
                {text: 'Φρούτα',                    value: '2'},
                {text: 'Λαχανικά',                  value: '3'},
                {text: 'Κρέας & Αυγά',              value: '4'},
                {text: 'Όσπρια',                    value: '5'},
                {text: 'Ψάρια & θαλασσινά',         value: '6'},
                {text: 'Ξηροί καρποί',              value: '7'},
                {text: 'Λίπη & έλαια',              value: '8'},
                {text: 'Γλυκά, γλυκαντικά & σνακ',  value: '9'},
                {text: 'Μπαχαρικά & Μυρωδικά',      value: '10'},
                {text: 'Ποτά',                      value: '11'},
                {text: 'Δεν ξέρω',                  value: '12'},
            ],
            selected: "12", 
            count: 4,
            acceptIngredient:"deny",
        }
    },
    mounted(){
        // this.getRandomIngredient();
        // this.getingredient();
        this.activation();
    },
    beforeMount() {
    },
    computed: {
      ...mapGetters({
        user: "user"
      }),
    },
    methods:{
        getingredient(){
          if(this.$props.recipe_id){
            fetch(process.env.VUE_APP_API+`/recipes/ingredientforvote/`+this.$props.recipe_id,{
                method: "get",
                headers: {
                    "TOKEN":JSON.stringify(this.user.data.token),
                    'Content-Type': 'application/json'
                },
                // body: JSON.stringify({category: this.categories[this.selected].text})
            }).then(res => res.json())
              .then(res => {
                if(res.error){
                    this.error = res.error;
                } else {
                  if(!res.ingredient){
                    this.error = "No results";
                  }else{
                    this.ingredient = res.ingredient;
                    this.isActive = true;
                  }
                }
            });              
          }
          else if(this.$props.ingredient_id){
            fetch(process.env.VUE_APP_API+`/ingredients/ingredientforvote/`+this.$props.ingredient_id,{
                method: "get",
                headers: {
                    "TOKEN":JSON.stringify(this.user.data.token),
                    'Content-Type': 'application/json'
                }
            }).then(res => res.json())
              .then(res => {
                if(res.error){
                    this.error = res.error;
                } else {
                  if(!res.ingredient){
                    this.error = "No results";
                  }else{
                    this.ingredient = res.ingredient;
                    this.isActive = true;
                  }
                }
            });
          }

        },
        activation() {
            if ((localStorage.clickcount) && (localStorage.clickcount < this.count)) {
                localStorage.clickcount = Number(localStorage.clickcount) + 1;
                this.isActive = false;
            } else {
                localStorage.clickcount = 1;
                this.getingredient();
            }
        },
        submitIngredientCategory() {
            // console.log(this.categories[this.selected].text);

            return new Promise((resolve,reject)=>{

                // var ingredient_form = {category: this.categories[this.selected].text} <- den xreiazetai
                fetch(process.env.VUE_APP_API+`/ingredients/update/`+this.ingredient+'/category',{
                    method: "post",
                    headers: {
                        "TOKEN":JSON.stringify(this.user.data.token),
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({category: this.categories[this.selected].text})
                }).then(res => res.json())
                    .then(res => {
                        if(res.error){
                            this.errors = res.error
                        }
                        else {
                            this.isActive=false;
                        }
                        resolve();
                    });
            });


        },
    }
}
</script>

<style scoped>

    .dark .window {
        background-color: var(--color-text-primary);
        color: var(--color-text-primary-dark);
    }
    .window {
        max-width: 400px;
        position: -webkit-sticky;
        position: sticky;
        bottom: 1.4rem;
        right: 1.4rem;
        padding: 1rem;
        float: right;
        z-index: 1;

        background-color: rgba(255, 255, 255, 0.884);
        border-radius: 10px;
        box-shadow: 0 0.4rem 10px #0000003b;
        display: none;
        transition: all 0.3s ease;
        backdrop-filter: blur(4px);

    }
    .window:hover {
        bottom: 2rem;
        box-shadow: 2px 1rem 16px #0000003b;
    }
    .active {
        display: block;
    }
    .close_btn {
        width: 0.7rem;
        height: 0.7rem;
        fill: var(--color-grey-2);
        float: right;
        margin-top: 0.3rem;
        cursor: pointer;
    }
    .close_btn:hover {
        fill: var(--color-text-primary);
    }
    h5{
        margin-bottom: 1rem;
    }
    p {
        margin-bottom: 0.5rem;
    }
    .text_input {
        /* width:100%; */
        padding: 0.5rem 0.5rem;
    }
    .primary_btn {
        float:right;
    }
    form {
        display: flex;
    }
    form input {
        margin: 0.6rem 0rem 0 0.5rem;
        padding: 0.6rem 1.4rem;
    }

</style>