<template>
    <div class="substep_form">
        <div>
            <button type="button" @click="emitDeleteEvent" class="primary_btn del_btn"> - </button>
        </div>
        <div>
            <div class="description">
                <label for="description">Περιγραφή βήματος: <MandatorySign v-bind:mandatory="false"/></label>
                <textarea rows="4" type="text" v-model="description" v-on:input="updateValueToParent()" name="description" class="text_input"/>
            </div>
            <div class="img">
                <label for="img">Εικόνα ή βίντεο: <MandatorySign v-bind:mandatory="false"/></label>
                <input type="file" name="substep_img" id="img" v-on:change="handleFileChange()" ref="substep_img" class="text_input">
            </div>
        </div>

    </div>
</template>


<script>
import MandatorySign from "../extras/Mandatory_sign.vue"

export default {
    name:'NewSubstep',
    props: [ 'given_id','given_description' ],
    components: {
        MandatorySign
    },
    data(){
        return {
            img:"",
            id:this.$props.given_id,
            description:this.$props.given_description || ""
        }
    },
    beforeMount(){
    },
    methods:{
        handleFileChange(){
            this.img = this.$refs.substep_img.files[0];
            this.updateValueToParent();
        },
        updateValueToParent: function () {
            this.$emit('input', { 
                id:this.id,
                img:this.img,
                description:this.description,
            })
        },
        emitDeleteEvent(){
            this.$emit("remove")
        }
    }

}
</script>

<style scoped>

.substep_form {
    display: flex;
    justify-content: space-between;
     /* margin-top: 1rem; */


}

.substep_form div {
    display: flex;
    flex-wrap: wrap;
}

.substep_form div div {
    margin-top: 1rem;
}

.substep_form div * {
    width: 100%;
}

.description {
    width: 100%;
}
.img {
    width: 100%;
}

.del_btn {
    margin-top: 1.8rem;

}


</style>