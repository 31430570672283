<template>

        <div class="problem-content">
            <div class="problem-sign" @click="open_form=true">
                <h6>
                    Βρήκες πρόβλημα;
                </h6>
                <p class="font-size-sm style-italic">
                    Η σελίδα βρίσκεται σε beta έκδοση. <br>Αν αντιμετωπίσατε κάποιο πρόβλημα, θα μας βοηθήσετε αν το αναφέρετε.
                </p>
            </div>
            <div class="problem-modal" v-if="open_form==true" >
            
                <div class="problem-form" v-click-outside="onClickOutside">
                    <form class="myForm" @submit="sendEmail">
                        <h5 class="text-center">Αναφορά προβλήματος</h5>

                        <!-- <label for="name"> Όνομα </label>  -->
                        <input class="text_input" v-model="name" type="text" id="name" name="name" placeholder="Όνομα...">

                        <!-- <label for="message"> Μήνυμα </label> -->
                        <textarea class="text_input" v-model="message" id="subject" name="message" placeholder="Γράψτε μας το πρόβλημα που αντιμετωπίσατε..." style="height:160px"></textarea>

                         <vue-recaptcha
                            class="recaptcha_component"
                            @verify="onVerify"
                            ref="recaptcha"
                            sitekey="6LdeMH8aAAAAALyovrZpwaFG5cydhkMdoAPnDIFj">
                        </vue-recaptcha>
                        <p v-if="errorMessage" class="error_message ">{{errorMessage}}</p>

                        <input class="primary_link form_btn" type="submit" value="Αποστολή">

                    </form>

                </div>
           
            </div> 
            <ActionModal  v-if="send"
                title="Επιτυχία"
                description="Ευχαριστούμε, το μήνυμά σας στάλθηκε με επιτυχία."
                action_btn="Τέλεια!"
                btn_type="action_btn"
                v-on:accept-action="send = false"
                v-on:decline-action="send = false"
            />
        </div>
</template>

<script>
import emailjs from 'emailjs-com';
import ActionModal from './Action_modal.vue';
import VueRecaptcha from 'vue-recaptcha';
import vClickOutside from 'v-click-outside'


export default {
    name: 'ProblemSign',
    data(){
        return {  
            open_form:"false",

            name:"",
            email:"Αναφορά προβλήματος",
            message:"",
            send:false,
            errorMessage:false,
            recaptcha:""
        }
    },
    directives: {
      clickOutside: vClickOutside.directive
    },
    components: {
        ActionModal,
        VueRecaptcha
    },
    methods:{
        onClickOutside () {
        this.open_form=false;
      },
        onVerify(response) {
            this.recaptcha = response;
        },
        sendEmail(e) {
            e.preventDefault()
            try {
                if(this.recaptcha){                   
                    emailjs.sendForm('service_q1bjfnt', 'template_9ci38c9', ".myForm",
                        'user_OZ6d8S9eqlUnlCw3YE73Q', {
                        name: this.name,
                        email: this.email,
                        message: this.message
                    }) ;
                    this.send=true;

                    // Reset form field
                    this.name = ''
                    this.email = 'Αναφορά προβλήματος'
                    this.message = ''
                } else {
                    this.errorMessage= "Είσαι ρομπότ;"
                }

            } catch(error) {
                console.log({error})
            }
        },
    }

}
</script>

<style lang="scss" scoped>
.problem-content {
    position: sticky;
    width: fit-content;
    bottom: 0rem;
    z-index: 1;
}

.problem-sign {
    background-color: var(--color-grey-3);
    border-radius: 18px;
    padding: 0.5rem 0.8rem;
    position: relative;
    left: 10px;
    bottom:1.2rem;
    opacity: 0.8;
    width: 160px;
    height: 36px;
    overflow-y: hidden;
    backdrop-filter: blur(4px);
    transition: 0.3s all ease;
    &:hover {
        opacity: 1;
        cursor: pointer;
        left: 0;
        height: 100px;
        width: 274px;

    }
    p {
        opacity: 0.8;
        line-height: 1.3;

    }
}
.problem-modal {
    position: absolute;
    bottom: 0px;
    width: 100vw;
    height: 100vh;
    background-color: #00000096;
    
}
.problem-form {
    max-width: 480px;
    margin: 1rem auto;
    padding: 1.2rem;
    input {
        margin: 1rem auto !important;
    }
    h5 {
        margin-bottom: 1rem;
    }
    .form_btn {
        padding: 0.5rem;
        text-align: center;
    }
    #email {
        display: none;
    }
    .myForm {
        background-color: var(--color-bg-primary);
        padding: 1rem 1.5rem;
        border-radius: 10px;
    }
}
.dark {
    .myForm {
        background-color: var(--color-bg-primary-dark);
    }
    .problem-sign {
        background-color: var(--color-darker-blue);
    }
}

</style>