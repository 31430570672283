<template>
    <section>
        <div class="container">
            <h1>Μερικές ιδέες</h1>

            <RecipeDeck 
            :results="results" 
            :AND_max_length="AND_max_length"
            :OR_max_length="OR_max_length"
            />

            <router-link :to="{ name: 'Search'}" class="primary_btn"  > Περισσότερες συνταγές </router-link >
        </div>
    </section>
</template>

<script>
    import RecipeDeck from '../extras/Recipe_deck.vue';

export default {
    name: 'RecipesSection',
    data(){
        return {  
            results: [],
            AND_max_length:4,
            OR_max_length:0,
        }
    },
    components: {
        RecipeDeck,

    },
    beforeMount() {
        this.getRecipes();
    },
    methods:{
        getRecipes: function() {
            this.results = [];
            fetch(process.env.VUE_APP_API + `/recipes/getall`)
                .then(res => res.json())
                .then(res => {
                    if (res.error) {
                        this.error = res.error;
                    } else {
                        if (res.length < 1) {
                            this.error = 'No results';
                        } else {
                            this.results = res;
                            this.results = this.results.map((entry)=>{
                                if(entry.central_media){
                                    let image_path = entry.central_media.split("https://cookingapi.handbook.gr")[1]
                                    entry.central_media = process.env.VUE_APP_API + image_path
                                }
                                return entry
                            })
                        }
                    }
                });
        },
    }
    
}
</script>

<style lang="scss" scoped>

</style>