<template>
    <div class="profile_container container" v-if="user && user.loggedIn">
        <h1>{{title}}</h1>

        <router-link :to="'/user'" class="back_btn">Πίσω </router-link>

        <div class="option">
            <h3> Αλλαγή κωδικού </h3>
            <p class="t-details-color style-italic">Θα σας έρθει email επιβεβαίωσης και από εκεί, θα μπορείτε να αλλάξετε τον κωδικό σας.</p>
            <button @click="requestPasswordChange" class="primary_link btn_left">Αλλαγή κωδικού</button>
        </div>
        <div class="option">
            <h3> Σύνδεση με facebook </h3>
            <p class="t-details-color style-italic">Συνδέστε τον λογαριασμό facebook σας. </p>
            <!-- <button v-if="!checkIfFacebookConnected()" @click="linkFacebookAccount"> Σύνδεση με λογαριασμό Facebook</button> -->
            <button v-if="!checkIfFacebookConnected()" @click="linkFacebookAccount" class="primary_btn fb_btn btn_left">
              <svg class="details_icons"><use href="@/assets/icon_sprite.svg#fb_icon"/></svg>   
              Σύνδεση Facebook
            </button>
            <p v-if="checkIfFacebookConnected()" class="style-italic"> Είστε συνδεδεμένος!</p>
        </div>
        <div class="option">
            <h3> Σύνδεση με google </h3>
            <p class="t-details-color style-italic">Συνδέστε τον λογαριασμό google σας. </p>
            <!-- <button v-if="!checkIfGoogleConnected()" @click="linkGoogleAccount">Σύνδεση με λογαριασμό Google</button> -->

            <button v-if="!checkIfGoogleConnected()"  @click="linkGoogleAccount" class="primary_btn gg_btn btn_left">
              <svg class="details_icons"><use href="@/assets/icon_sprite.svg#google_icon"/></svg>   
              Σύνδεση Google
            </button>

            <p v-if="checkIfGoogleConnected()" class="style-italic"> Είστε συνδεδεμένος!</p>
        </div>
        <hr class="dashed-border-top"/>
        <div class="option">
          <h3> Διαγραφή λογαριασμού</h3>
          <p class="t-details-color style-italic">Διαγράψτε οριστικά τον λογαριασμό σας και όλα τα προσωπικά σας δεδομένα.</p>
          <!-- <button class="primary_btn warning_btn btn_left" @click="delete_warning=true">Διαγραφή</button> -->
          <button class="primary_link warning_link btn_left" @click="delete_warning=true">Διαγραφή</button>
        </div>

        <ActionModal  v-if="delete_warning"
          title="Οριστική διαγραφή"
          description="Θέλετε σίγουρα να διαγράψετε οριστικά τον λογαριασμό σας; Θα διαγραφούν οι συνταγές και τα προσωπικά σας δεδομένα."
          action_btn="Διαγραφή"
          back_btn= "Ακύρωση"
          btn_type="warning_btn"
          v-on:accept-action="deleteAccount"
          v-on:decline-action="delete_warning = false"
        />

        <hr class="dashed-border-top"/>

        <input type="button" @click="submitProfileChanges" value="Επιστροφή στο προφίλ" class="primary_btn ">



    </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "../store";
import firebase from "firebase";

import ActionModal from './extras/Action_modal.vue';

export default {
    

    name: 'EditAccount',
     components: {
      ActionModal
    },
    data(){
        return {
            title:"Επεξεργασία λογαριασμού",
            delete_warning: false,
        }
    },
    computed: {
      ...mapGetters({
        user: "user"
      })
    },
    methods: {
        requestPasswordChange:function(){
        if(this.user.data && this.user.data.email){
            firebase.auth().sendPasswordResetEmail(this.user.data.email).then(function() {
            // Email sent.
            }).catch(function(error) {
            // An error happened.
            console.log(error)
            });
        }
        },
        checkIfGoogleConnected:function(){
        let return_val = false;
        if(this.user && this.user.data.token){
            for(let provider of this.user.data.providerData){
            if(provider.providerId == "google.com"){
                return_val=true;
                break;
            }
            }
        }else{
            return_val = false;
        }

        return return_val;
        },
        checkIfFacebookConnected:function(){
        let return_val = false;
        if(this.user && this.user.data.token){
            for(let provider of this.user.data.providerData){
            if(provider.providerId == "facebook.com"){
                return_val=true;
                break;
            }
            }
        }else{
            return_val = false;
        }

        return return_val;
        },
        linkGoogleAccount:function(){
        var provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().currentUser.linkWithPopup(provider).then(function(result) {
            var credential = result.credential;
            var user = result.user;
            window.location.reload()
        }).catch(function(error) {
            console.log(error)
        });
        },
        linkFacebookAccount:function(){
        var provider = new firebase.auth.FacebookAuthProvider();
        firebase.auth().currentUser.linkWithPopup(provider).then(function(result) {
            var credential = result.credential;
            var user = result.user;
            window.location.reload()
        }).catch(function(error) {
            console.log(error)
        });
        },
        logout:function(){
            store.dispatch("logout");
            this.$router.push({ path: '/'})
        },
        submitProfileChanges:function() {
            // this.$router.push({ path: '/user'})
            this.$router.push({ path: '/user/'+this.user.data.displayName})


        },
        deleteAccount: function() {
            fetch(process.env.VUE_APP_API+`/user/delete`, { 
                    method: 'DELETE',
                    headers:{"TOKEN":JSON.stringify(this.user.data.token)},
                })
                .then(res => res.json())
                .then(res => {
                    if(res.error){
                        this.error = res.error;
                    }else{
                        store.dispatch("logout");
                        this.$router.push({ path: '/'})

                    }
                })
        }
        
    }
    
}
</script>

<style scoped>

h3 {
    margin-bottom: 2rem;
}
.option {
    margin: 3rem 0;
}
/* .primary_link {
    margin: 0.2rem;
} */
.primary_btn{
    margin:3rem auto;
}

.fb_btn {
        background-color: #0099ff;
    }
.gg_btn {
    background-color: var(--color-red); 
}

</style>