<template>
    <div v-if=" user.data">
        <Profile
            :profile="profile"
            :results="results"
        />
    </div>
    <div v-else>
        <h1>Δεν έχεις συνδεθεί.</h1>
        <router-link to="/login" class="primary_btn add_recipe">Σύνδεση ή Εγγραφή</router-link>


    </div>
</template>

<script>
import { mapGetters } from "vuex";

import firebase from "firebase";

import Profile from './extras/Profile.vue';


export default {
    name:'UserProfile',
    components: {
        Profile
    },
    data(){
        return {
            results: [],
            profile: {},
        }
    },
    computed: {
      ...mapGetters({
        user: "user"
      })
    },
    watch: {
        "$store.getters.user.data": function () {
            if(this.user.data.profile){
                this.profile = this.user.data.profile;
                this.getRecipes(); 
            }
        }
    },
    mounted(){
        if(this.user.data && this.user.data.profile){
            this.profile = this.user.data.profile
            this.getRecipes(); 
        }
    },
    methods: {
        getRecipes: function() {
            this.results = [];
                fetch(process.env.VUE_APP_API+`/user/recipes/`+this.profile.email)
                .then(res => res.json())
                .then(res => {
                    if(res.error){
                        this.error = res.error;
                    } else {
                        if(res.length < 1){
                            this.error = "No results";
                        }else{
                            this.results = res;
                        }
                    }
                });
        },
    }
  
    
}
</script>

<style lang="sass" scoped>

</style>