<template>
    <div>
        <div v-if="user.data">
            <h1>{{ $t('recipe.create') }}</h1>

            <div class="myForm">
                <h3>{{ $t('recipe.sections.intro') }}</h3>
                <label for="name">{{ $t('recipe.intro.title') }}: <MandatorySign v-bind:mandatory="true"/></label>
                <p class="subtext">{{ $t('subtexts.title') }}</p>
                <input v-bind:class="{ error: checkFieldError('name')}" v-model="recipe_data.name" type="text" name="name" id="name" class="text_input" maxlength="50" minlength="5" placeholder="Τίτλος συνταγής" >

                <label for="central_media">{{ $t('recipe.intro.central_media') }}: <MandatorySign v-bind:mandatory="false"/></label>
                <p class="subtext"> {{ $t('subtexts.media') }}</p>
                <input type="file" v-on:change="handleFileChange()" ref="central_media" name="central_media" id="central_media" class="text_input">

                <label for="description">{{ $t('recipe.intro.description') }}: <MandatorySign v-bind:mandatory="false"/></label>
                <p class="subtext"> {{ $t('subtexts.description') }}</p>
                <textarea rows="5" v-model="recipe_data.description" name="description" id="description" class="text_input"/>

                <h3>{{ $t('recipe.sections.ingredients') }}</h3>

                <p class="subtext" v-html="$t('subtexts.ingredients')"></p>

                <div ref="ingredients">
                    <div class="text_row" >
                        <div class="first_empty"> </div>
                        <div class="ing_name_text">{{ $t('recipe.ingredient.name') }}: <MandatorySign v-bind:mandatory="true"/></div>
                        <div class="ing_quantity_text">{{ $t('recipe.ingredient.quantity') }}: <MandatorySign v-bind:mandatory="true"/></div>
                        <div class="ing_units_text">{{ $t('recipe.ingredient.unit') }}: <MandatorySign v-bind:mandatory="true"/></div>
                        <div class="ing_details_text" >{{ $t('recipe.ingredient.details') }}: <MandatorySign v-bind:mandatory="false"/></div>
                    </div>
                    <NewIngredient
                        :ref=ingredient.id
                        v-on:remove="removeIngredient(index)"
                        v-for="(ingredient,index) in ingredients"
                        :key="ingredient.id"
                        :id="ingredient.id"
                        :errors="errors[ingredient.id] || null"
                        v-model="ingredients[index]"
                    />
                </div>

                <button type="button" v-on:click="addNewIngredient" class="add_btn primary_btn ">+
                  <span class="tooltip_add" >{{ $t('recipe.ingredient.add') }}</span>
                </button>


                <h3>{{ $t('recipe.sections.utensils') }}</h3>
                <p class="subtext" v-html="$t('subtexts.utensils')"> </p>
                <div ref="utensils">
                    <div class="text_row" >
                        <div class="first_empty"> </div>
                        <div class="u_name_text">{{ $t('recipe.utensil.name') }}:        <MandatorySign v-bind:mandatory="false"/></div>
                        <div class="u_quantity_text">{{ $t('recipe.utensil.quantity') }}:  <MandatorySign v-bind:mandatory="false"/></div>
                        <div class="u_details_text" >{{ $t('recipe.utensil.details') }}: <MandatorySign v-bind:mandatory="false"/></div>
                    </div>
                    <NewUtensil
                        v-on:remove="removeUtensil(index)"
                        v-for="(utensil,index) in utensils"
                        :key="'utensil_'+index"
                        v-model="utensils[index]"
                    />
                </div>

                <button type="button" v-on:click="addNewUtensil" class="primary_btn add_btn">+
                    <span class="tooltip_add">{{ $t('recipe.utensil.add') }}</span>
                </button>

              <h3>{{ $t('recipe.sections.steps') }}</h3>
                <p class="subtext" v-html="$t('subtexts.steps')"></p>
                <div ref="steps">
                    <NewStep
                    :ref=step.id
                    v-on:remove="removeStep(index)"
                    v-for="(step,index) in steps"
                    :key="step.id"
                    v-model="steps[index]"
                    :given_id="step.id"
                    :errors="errors[step.id] || null"
                    shouldAddNewSubstep="false"
                    />
                </div>

                <button type="button" v-on:click="addNewStep" class="primary_btn add_btn">+
                    <span class="tooltip_add">{{ $t('recipe.steps.add') }}</span>
                </button>

                <h3>Λεπτομέρειες</h3>
                <p class="subtext" v-html="$t('subtexts.details')">  </p>
                <div class="last_details row">
                    <div class="col-sm-3">
                        <label for="cooking_time">{{ $t('recipe.details.cooking_time') }}: <MandatorySign v-bind:mandatory="true"/></label>
                        <input v-bind:class="{ error: checkFieldError('cooking_time')}" v-model="recipe_data.cooking_time" type="number" name="cooking_time" id="cooking_time" class="text_input" step="5" placeholder="0" min="0">
                    </div>
                    <div class="col-sm-3">
                        <label for="waiting_time">{{ $t('recipe.details.waiting_time') }}: <MandatorySign v-bind:mandatory="true"/></label>
                        <input v-bind:class="{ error: checkFieldError('waiting_time')}" v-model="recipe_data.waiting_time" type="number" name="waiting_time" id="waiting_time" class="text_input" step="5" placeholder="0" min="0">
                    </div>
                    <div class="col-sm-3">
                        <label for="portions">{{ $t('recipe.details.portions') }}: <MandatorySign v-bind:mandatory="true"/></label>
                        <input v-bind:class="{ error: checkFieldError('portions')}" v-model="recipe_data.portions" type="number" name="portions" id="portions" class="text_input" placeholder="4" min="0">

                    </div>
                    <div class="col-sm-3">
                        <label for="difficulty">{{ $t('recipe.details.difficulty') }}: <MandatorySign v-bind:mandatory="true"/></label>
                        <input v-bind:class="{ error: checkFieldError('difficulty')}" v-model="recipe_data.difficulty" type="number" name="difficulty" id="difficulty" class="text_input"  min="1" max="3" placeholder="1-3">

                    </div>
                </div>


                <label for="tags">Ετικέτες: <MandatorySign v-bind:mandatory="false"/></label>
                <p class="subtext" v-html="$t('subtexts.tags')"></p>

                <vue-tags-input
                    v-model="tag"
                    :tags="recipe_data.tags"
                    @tags-changed="newTags => recipe_data.tags = newTags"
                    name="tags"
                    class="text_input vue_tags"
                />

                <label for="tips">{{ $t('recipe.sections.tips') }}: <MandatorySign v-bind:mandatory="false"/></label>
                <p class="subtext"> {{ $t('subtexts.tips') }} </p>

                <textarea rows="3" v-model="recipe_data.tips" name="tips" id="tips" class="text_input"/>
                

                <input @click="submitRecipe" type="button" value="Καταχώρηση Συνταγής" class="primary_btn form_btn">

                <p class="error_message" v-if="errors.length!=0">Παρακαλώ ελέγξτε τα στοιχεία, κάποιο υποχρεωτικό πεδίο δεν έχει συμπληρωθεί.</p>
            </div>
        </div>
        <Login  doNotRedirect="true" v-if="!user.data"/>
    </div>

</template>


<script>

import Login from './Login'
import NewIngredient from './Recipe_form/New_ingredient.vue'
import NewUtensil from './Recipe_form/New_utensil.vue'

import NewStep from './Recipe_form/New_step.vue'

import MandatorySign from './extras/Mandatory_sign.vue'

import VueTagsInput from '@johmun/vue-tags-input';

import Vue from 'vue'

import { mapGetters } from "vuex";



export default {
    components: {
        VueTagsInput,
        NewIngredient,
        NewUtensil,
        NewStep,
        MandatorySign,
        Login
    },
    name: 'RecipeForm',
    data(){
        return {
            central_media:"",
            errors:[],
            tag:"",
            ingredients:[],
            ingredients_counter:0,
            utensils:[],
            utensils_counter:0,
            steps:[],
            steps_counter:0,
            autocompleteItems:[],

            recipe_data:{
                name:"",
                central_media:"",
                description:"",
                ingredients:[],
                steps:[],
                cooking_time:"0",
                waiting_time:"0",
                portions:"2",
                difficulty:"1",
                tags: [],
                tips:""
            }
        }
    },

    beforeMount(){
        this.addNewIngredient();
        this.addNewUtensil();
        this.addNewStep();
        this.getIngredients();

    },
    computed: {
      // map `this.user` to `this.$store.getters.user`
        ...mapGetters({
            user: "user"
        }),
        filteredItems() {
            return this.autocompleteItems.filter(i => {
                return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
        },

    },
    methods:{
        getIngredients: function(){
            fetch(process.env.VUE_APP_API+`/ingredients/getall`)
                .then(res => res.json())
                .then(res => {
                    res = res.map((entry)=>{
                        return {text:entry}
                    })
                    this.autocompleteItems = res
                })
        },
        checkFieldError(field_name){
          return this.errors[field_name] != null;
        },
        handleFileChange(){
            console.log(this.$refs.step_0[0].$refs[0][0].$refs.substep_img)
            this.central_media = this.$refs.central_media.files[0];
        },
        submitRecipe(){
            //get the data for all ingredients
            this.recipe_data.ingredients = this.ingredients;
            this.recipe_data.utensils = this.utensils;
            this.recipe_data.steps = this.steps;
            this.postRecipe()
        },
        postRecipe: function() {
            var recipe_form = new FormData()
            let step_counter=0;
            for(let step of this.recipe_data.steps){
                if (step.substeps && step.substeps.length>0) {
                    for(let substep of step.substeps){
                        recipe_form.append('step_'+step_counter+'_substep_'+substep.id+'_media', substep.img)
                    }
                }
                step_counter++;
            }
            recipe_form.append('central_media', this.central_media)
            recipe_form.append('recipe', JSON.stringify(this.recipe_data))
            fetch(process.env.VUE_APP_API+`/recipes`,{
                method: "post",
                headers: {
                    "TOKEN":JSON.stringify(this.user.data.token),
                },
                body: recipe_form
            }).then(res => res.json())
              .then(res => {
                  //TODO: Error Validation
                  if(res.error){
                      console.log(this.errors.length);
                      this.errors = res.error
                      console.log(this.errors.length);
                  }
                  else {
                    this.$router.push({ path: '/recipes/'+this.recipe_data.name})

                  }
              });
        },
        addNewIngredient() {
            let a_ingredient = {
                id:"ingredient_"+this.ingredients_counter++,
                data:{}
            }
            this.ingredients.push(a_ingredient)
        },
        removeIngredient(ingr_id) {
            Vue.delete(this.ingredients, ingr_id);
        },

        addNewUtensil() {
            this.utensils.push({})
        },
        removeUtensil(u_id) {
            Vue.delete(this.utensils, u_id);
        },

        addNewStep() {
            let a_step = {
                id:"step_"+this.steps_counter++,
            }
            this.steps.push(a_step);
        },
        removeStep(st_id) {
           Vue.delete(this.steps,st_id);
        },

    }
}
</script>

<style scoped>


.myForm {
    max-width: 750px;
}
h3 {
    margin-top: 4rem;
}
#name {
    font-size: 1.8rem;
}
/* .last_details {
    display: flex;
}
.last_details > * {
    width: 25%;
} */
.last_details input {
    width: -webkit-fill-available;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
}
.last_details label {
    margin-top: 1rem;
    min-height: 3rem;
}
.last_details.row div {
    padding-left: 5px;
    padding-right: 5px;
}
 .text_row{
    display: flex;
    justify-content: space-between;
    margin-top: 1.3rem;
    align-items: flex-end;

}
.first_empty {
    width: 40px;
}
.ing_name_text {     width: 23%; }
.ing_quantity_text { width: 14%; }
.ing_units_text {    width: 18%; }
.ing_details_text {  width: 38%; }
.u_quantity_text {   width: 14%; }
.u_name_text {       width: 30%; }
.u_details_text {    width: 48%; }

.vue_tags{
    max-width: unset;
}
/* .error {
  border: 3px solid var(--color-red) !important;
} */

</style>
