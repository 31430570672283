<template>
    <router-link :to="/recipes/+result.id" class="no_extra_styling">
        <div class="recipe_card" :id=result.id>
            <div v-if="result.central_media.length>0" class="central_img" :style="{ backgroundImage: 'url(' + result.central_media + ')' }"></div>
            <div v-else class="central_img default_recipe_img" ></div>
            <div class="txt">
                <h3>{{result.name}}</h3>
                <p class="descr">{{result.description}}</p>
            </div>
            <div class="overflow_shadow"></div>
            <!-- <Reactions v-if="result.id"
              :recipe_id="result.id"
              :reactions="result.reaction" 
             /> -->
            <div class="details">

                <svg class="" v-if="result.cooking_time>=0"><use href="@/assets/icon_sprite.svg#cooking_time"/></svg>   
                <p v-if="result.cooking_time >=0" >{{result.cooking_time}}'</p>
                <p v-if="result.cooking_time>=0" >| </p>
                <svg v-if="result.waiting_time>=0" class=""><use href="@/assets/icon_sprite.svg#waiting_time"/></svg>   
                <p v-if="result.waiting_time>=0" >{{result.waiting_time}}'</p>
                <p v-if="result.waiting_time>=0" >| </p>
                <svg class=""><use href="@/assets/icon_sprite.svg#portions"/></svg>   
                <p >{{result.portions}}</p>
                <p >| </p>
                <svg class=""><use href="@/assets/icon_sprite.svg#difficulty"/></svg>   
                <p >{{result.difficulty}}</p>
            </div>
            
        </div>
    </router-link>
</template>

<script>
// import Reactions from './Reactions.vue';


export default {
  components: {
    //   Reactions,
  },
  props: ["result"],
  name: 'Recipe_card',
  data() {
      return {
      }
  },
  methods:{
  }

}
</script>

<style scoped>



.recipe_card {
    display: block;
    text-align: left;
    border-radius: 6px;
    padding: 10px;
    margin: 0.6rem;
    /* border: 1px solid var(--color-primary); */
    cursor: pointer;
    /* transition: 0.2s all ease; */
}
.recipe_card:hover {
    border: 1px solid var(--color-secondary);
    padding: 9px;
}
.txt {
    overflow: hidden;
    height: 150px;
}
.overflow_shadow {
    display: block;
    background: linear-gradient( 0deg, rgb(255, 255, 255) 0%, rgba(255,255,255,10%) 100%);
    height: 2rem;
    position: relative;
    bottom: 2rem;
    margin-bottom: -2rem;
}
.dark .overflow_shadow {
    background: linear-gradient( 0deg, rgba(var(--color-bg-primary-dark-rgb),1) 0%, rgba(var(--color-bg-primary-dark-rgb),0) 100%);

}
h3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.central_img {
    min-height: 170px;
    border-radius:10px;
}
.details {
    font-size: 0.8rem;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}
.descr {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    font-size: 0.9rem;
}
.descr-fade{
    background: linear-gradient(0deg, rgba(var(--color-bg-primary-dark-rgb), 1), rgba(var(--color-bg-primary-dark-rgb), 0.2));
    height: 21px;
    position: relative;
    top: -30px;
    margin-bottom: -20px;
}
p {
    color: var(--color-grey-2);
}
.details p {
    margin: 0.2rem;
}
.details svg {
    width: 0.9rem;
    height: 0.9rem;
    margin: 0.2rem;
    fill: var(--color-grey-2);
}
@media (max-width: 575.98px) { 
    .central_img {
        min-height: 260px;
    }
 }

</style>
