<template>
  <div class="ingredient-chips" ref="slider" :class="{ 'ingredient-chips--slider': slider }" 
    @mousedown="mouseDown"  @mousemove="mouseMove" @mouseleave="mouseLeave" @mouseup="mouseUp"
  >
      <IngredientChip 
      v-for="ingredient in ingredients"
      :key="ingredient.id" 
      :ingredient="ingredient"      
      :slided="slided"
      />
  </div>
</template>

<script>
import IngredientChip from '../extras/IngredientChip.vue'

export default {
  components: {
    IngredientChip,
  },
  props: [
    "ingredients",
    "slider"
    ],
  name: 'IngredientChips',
  data() {
      return {
        isDown:false,
        startX:0,
        scrollLeft:0,
        slided:false,
      }
  },
  methods:{
    
    mouseDown(event) {
      this.slided = false;
      this.isDown = true;
      this.startX = event.pageX - this.$refs.slider.offsetLeft;
      this.scrollLeft = this.$refs.slider.scrollLeft;
    },
    mouseLeave(event) {
      this.isDown = false;
    },
    mouseUp(event) {
      this.isDown = false;
    },
    mouseMove(event) {
      if(!this.isDown) return;
      this.slided = true
      event.preventDefault();
      const x = event.pageX - this.$refs.slider.offsetLeft;
      const walk = (x - this.startX);
      this.$refs.slider.scrollLeft = this.scrollLeft - walk;
    }
  }
}
</script>

<style lang="scss">

.ingredient-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 1rem auto;
}
.ingredient-chips--slider {
  flex-wrap: nowrap;
  overflow-x: scroll;
  cursor: grab;
}
::-webkit-scrollbar{ 
  display: none; 
}
::-moz-scrollbar{ 
  display: none; 
}

</style>