<template>
    <section>
        <div class="container" v-if="results && results.length>0" id="ingredient-of-the-month">
            <h1>Το υλικό του μήνα!</h1>
            <p class="text-center">Κάθε μήνα θα αναδεικνύουμε ένα υλικό. Τον Γενάρη μιλάμε για ... {{ingredient_month}}!</p>
            <div class="row ingredient_intro">
                <div class="col-md-5" v-if="results[0].image"> 
                    <a :href="'/ingredients/'+ingredient_month"><div class="round_shadowed_img backgrounded_img" :style="{ backgroundImage: 'url(' + results[0].image + ')' }"></div></a>
                </div>
                <div class="col-md-7">
                    <h2>{{ingredient_month}}</h2>
                    <p>
                        <span class="t-details-color font-size-xs style-italic">
                            <svg class="ingr_details_icon"><use href="@/assets/icon_sprite.svg#portions"/></svg> 
                            Κατηγορία: 
                        </span>  
                        <span >  {{results[0].category}} </span>
                    </p>
                    <p v-if="results[0].description">
                        {{results[0].description}}
                    </p>
                    <router-link :to="'/ingredients/'+ingredient_month" class="primary_link"  > Δείτε περισσότερα</router-link >
                </div>
            </div>
            <h2 class="text-center">Μερικές συνταγές με {{ingredient_month}}</h2>

            <RecipeDeck 
            :results="recipies_with" 
            :AND_max_length="6"
            :OR_max_length="0"
            />
            <router-link to="/recipe-form" class="primary_btn"> Ανέβασε την δική σου συνταγή </router-link>

            <!-- <ingredientChip
              v-if="results && results.length>0"
              :ingredient="results[0]"
            />
            <ingredientChips
              v-if="results && results.length>0"
              :ingredients="[results[0],results[0],results[0],results[0],results[0],results[0],results[0],results[0],results[0]]"
            />
            <ingredientChips
              v-if="results && results.length>0"
              slider=true
              :ingredients="[results[0],results[0],results[0],results[0],results[0],results[0],results[0],results[0],results[0]]"
            /> -->

        </div>

    </section>
</template>

<script>
import RecipeDeck from '../extras/Recipe_deck.vue';
// import IngredientChip from '../extras/IngredientChip.vue'
// import IngredientChips from '../extras/IngredientChips.vue'


export default {
    name: 'IngredientSection',
    data(){
        return { 
            ingredient_month: "σοκολάτα" ,

            results:[],
            recipies_with:[],
            error:false,
            image:"",
        }
    },
    props: [ 
        'user',
    ],
    components: {
        RecipeDeck,
        // IngredientChip,
        // IngredientChips,

    },
    computed: {
    },
    beforeMount(){
      this.getingredient();
      this.getRecipesWithIngredient();
    },
    methods:{
      getRecipesWithIngredient: function(){
        fetch(process.env.VUE_APP_API+`/search/ingredients?ingredients=`+this.ingredient_month)
                .then(res => res.json())
                .then(res => {
                    this.searching = false;
                    if(res.error){
                        this.error = res.error;
                    }else{
                        if(res.length < 1){
                            this.error = "No recipes";
                        }else{
                            this.error = false;
                            this.recipies_with = res;
                        }
                    } 
                });
      },
      getingredient: function() {
        this.results = [];
        fetch(process.env.VUE_APP_API+`/ingredients/getByName/`+this.ingredient_month)
          .then(res => res.json())
          .then(res => {
            if(res.error){
              this.error = res.error;
            }else{
              if(res.length < 1){
                this.error = "No results";
              }else{
                this.results = res;
            }
            }
          });
      },
    }
    
}
</script>

<style lang="scss" scoped>
    h1 {
        color: var(--color-orange);
        // color: var(--color-primary);
        &:before {
            content: "";
            width: calc(44% - 160px);
            position: absolute;
            border: 2px solid var(--color-orange);
            background: var(--color-orange);
            left: 3%;
            margin-top: 26px;
        }
        &:after {
            content: "";
            width: calc(44% - 160px);
            position: absolute;
            border: 2px solid var(--color-orange);
            background: var(--color-orange);
            right: 3%;
            margin-top: 26px;
        }
    }
    .ingredient_intro {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    h2 {
        margin-bottom: 2rem;
    }
    span {
        margin-left: 0.5rem;
    }
    .text-center {
        text-align: center;
    }
    .primary_link {
        text-align: left;
    }
    .round_shadowed_img:hover {
        box-shadow: 23px 16px 0px -17px var(--color-secondary);

    }
    // section {
    //     padding-bottom: 2rem;
    //     border-bottom: 2px solid var(--color-orange);

    // }
</style>