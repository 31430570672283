<template>
    <div>
       <h1>Καλως ήρθες!</h1>

       <div class="small_container">

       <ul>
          <li >
              <router-link to="/login" class="active_tab tab">Σύνδεση</router-link>

          </li>
          <li>
              <router-link to="/signup" class="inactive_tab tab" >Εγγραφή</router-link>
          </li>
        </ul>

        <SocialLoginButtons :doNotRedirect="doNotRedirect"/>

        <div class="solid-border-top">
          <span class="or"> ή </span>
        </div>

        <div class="myForm">
          <label for="username">Email χρήστη:</label>
          <input v-model="form.email" type="email" name="username" id="username" class="text_input">
          <label for="password">Κωδικός:</label>
          <input  v-model="form.password" type="password" name="password" id="password" class="text_input">
          <input  @click="submit" type="submit" value="Σύνδεση" class="primary_btn form_btn">
        </div>

        <div class="alert alert-danger" v-if="error">{{error}}</div>
      
      </div>

    </div>

    
</template>

<script>
import { mapGetters } from "vuex"; 
import firebase from "firebase";
import SocialLoginButtons from "./extras/Social_login_btns.vue";


export default {
  name: 'Login',
  computed: {
      // map `this.user` to `this.$store.getters.user`
      ...mapGetters({
        user: "user"
      })
  },
  props: [ 
        'doNotRedirect',
  ],
  components: {
    SocialLoginButtons,
  },
  data(){
    return {
      form:{
        email:    "",
        password: ""
      },
      error:false
    }
  },
  methods:{
    submit() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then(() => {
          const analytics = firebase.analytics()
          analytics.logEvent("user_logged_in_using_emailAndPassword")
          if(this.$props.doNotRedirect != "true"){
            if(this.$route.query.fwd){
              this.$router.push(this.$route.query.fwd)
            }else{
              this.$router.replace({ name: "Search" });
              // this.$router.push({ path: '/user/'+this.user.data.displayName})
            }
          }
        })
        .catch(err => {
          this.error = err.message;
      });
    }
     
  }

}
</script>

<style scoped>

h1 {
  margin-bottom: 4rem;
}
ul {
  list-style: none;
  display: inline-flex;
  padding: 0;
  max-width: 400px;
  width: 100%;
  justify-content: center;
  border-bottom: 3px solid var(--color-text-primary);
}
li {
   width: 50%;
 }

</style>
