<template>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-md-6 hero_txt">
                    <h1 > Τόσα υλικά, τόσες συνταγές, <br> τι να πρωτοδιαλέξεις!</h1>
                    <h4 >Ας πάρουμε τα πράγματα από την αρχή: <br>τι έχεις στο ψυγείο σου;</h4>
                    <router-link :to="{ name: 'Search'}" class="primary_btn"  >
                        <svg class=""> <use href="@/assets/icon_sprite.svg#search_icon"/> </svg>
                        {{ $t('search_section.button_value') }}
                    </router-link >

                </div>
                <div class="col-md-6 col-img">
                    <img class="hero_img round_shadowed_img" src="@/assets/cat-fridge_1500.jpg" alt="">

                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: 'HeroSection',
    data(){
        return {  
    
        }
    },
    components: {
    },
    methods:{
    }
    
}
</script>
<style lang="scss">

    .col-img {
        padding: 2rem;
    }
</style>

<style lang="scss" scoped>
    .row {
        align-items: center;
    }
    // .hero_img {
    //     width: 100%;
    //     border-radius: 50%;
    //     box-shadow: -25px -12px 0px -10px var(--color-secondary);
    //     max-width: 350px;

    // }
    .hero_txt {
        order:1;
        h3, h2, h4 {

            font-weight: 400 !important;
            margin-bottom: 2rem;
            text-align: center;
        }
        
        // .primary_btn {
        //     margin-right: 0;
        // }
    }
    @media (min-width: 768px) { 
        h1 {
            margin-top: 1rem;
        }
        .hero_txt {
            order: 0;
            h3, h2, h4, h1 {
                text-align: right;
            }
            .primary_btn {
                margin-right: 0;

            }
        }
     }

</style>