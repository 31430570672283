import Home from './components/Home.vue';
import Search from './components/Search.vue';
import RecipeInternal from './components/Recipe_internal.vue';
import RecipeForm from './components/Recipe_form.vue';
import Login from './components/Login.vue';
import Signup from './components/Signup.vue';
import PublicProfile from './components/Public_profile.vue';
import EditProfile from './components/Edit_profile.vue';
import EditAccount from './components/Edit_account.vue';
import IngredientInternal from './components/Ingredient_internal.vue';
import Design from './components/Design.vue';
import ContactUs from './components/Contact_us.vue';
import AboutUs from './components/About_us.vue';

import UserProfile from './components/User_profile.vue';

export default [
    {
        path: '/',
        component: Home,
        name: "Home"
    },
    {
        path: '/search',
        component: Search,
        name: "Search"
    },
    {
        path: '/recipes/:id',
        component: RecipeInternal,
        name: "RecipeInternal"
    },
    {
        path: '/recipe-form',
        component: RecipeForm,
        name: "RecipeForm"
    },
    {
        path: '/login',
        component: Login,
        name: "Login"
    },
    {
        path: '/signup',
        component: Signup,
        name: "Signup"
    },
    {
        path: '/user/:username',
        component: PublicProfile,
        name: "PublicProfile"
    },
    {
        path: '/user',
        component: UserProfile,
        name: "UserProfile"
    },
    {
        path: '/edit-profile',
        component: EditProfile,
        name: "EditProfile"
    },
    {
        path: '/edit-account',
        component: EditAccount,
        name: "EditAccount"
    },
    {
        path: '/ingredients/:name',
        component: IngredientInternal,
        name: "IngredientInternal"
    },
    {
        path: '/design',
        component: Design,
        name: "Design"
    },
    {
        path: '/contactus',
        component: ContactUs,
        name: "ContactUs"
    },
    {
        path: '/about-us',
        component: AboutUs,
        name: "AboutUs"
    },

]
