<template>
    <section >
        <div class="container">

            <div class="row">
                <div class="col-md-6">
                </div>
                <div class="col-md-6" v-if="user && !user.loggedIn">
                    <h2>Ανέβασε τις δικές σου συνταγές</h2>
                    <p>
                        Γίνε μέρος της κοινότητας τώρα! <br> Φτιάξε το προφίλ σου και ανέβασε συνταγές. <br>Είστε όλοι ευπρόσδεκτοι!
                    </p>
                    <router-link  to="/login" class="primary_btn"> Σύνδεση / Εγγραφή </router-link>

                </div>
                <div class="col-md-6" v-else>
                    <h2>Ανέβασε τις δικές σου συνταγές</h2>
                    <p>
                        Γίνε μέρος της κοινότητας. <br> Φτιάξε το προφίλ σου και ανέβασε συνταγές!
                    </p>
                    <router-link to="/recipe-form" class="primary_btn"> Νέα Συνταγή </router-link>

                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: 'JoinusSection',
    data(){
        return {  
        }
    },
    props: [ 
        'user',
    ],
    computed: {
    },
    
}
</script>

<style lang="scss" scoped>

section {
    // background: linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, var(--color-grey-3) 28%, var(--color-grey-3) 100%), url('~@/assets/cat-fridge_1500.jpg');
        
        background-image: url('~@/assets/katsarola.png');
        background-color: var(--color-grey-3);
        background-size: contain;
        background-repeat: no-repeat;

    * {

        text-align: center !important;
    }
}
.dark {
    section {
        background-color: var(--color-darker-blue);
    }
}
@media (max-width:700px) {
    section {
        background-size: cover;
        background-position-x: right;
        
    }    
}

</style>