<!--
this components is used when the user can edit a part of his recipe.
we call it like this:
<EditButton 
    isEditing="..."
    :I_am_the_author="I_am_the_author"
    align="center | left | right (default)"
    text="... (optional)" 
    border="no-border | top-border | bottom-border (default)"
    v-on:edit-pressed="..."
    v-on:save-pressed="..."
/>
-->

<template>
    <div class="edit_links" :class="[align , border]" >
        <button @click="$emit('edit-pressed')" v-if='!isEditing && I_am_the_author' class="primary_link edit_link font-size-sm"> 
            <svg class=""><use href="@/assets/icon_sprite.svg#edit_icon"/></svg> 
            Επεξεργασία {{txt}}
        </button>
        <button @click="$emit('save-pressed')"  v-if='isEditing' class="primary_link edit_link font-size-sm"> 
            <svg class=""><use href="@/assets/icon_sprite.svg#save_icon"/></svg> 
            Αποθήκευση {{txt}}
        </button> 
    </div>
</template>

<script>
export default {
    name:"EditButton",
    props: [ 
        'isEditing',
        'I_am_the_author',
        'align',
        'text',
        'border'
    ],
    data(){
        return {
            txt:this.text || "πεδίων",
        }
    }
}
</script>

<style scoped>

    .edit_links {
        display: block;
        width: fit-content;
        margin-bottom: 1.3rem;
        margin-top: 0.3rem;
        /* border-bottom: 1px solid var(--color-primary); */

    }
    .no-border{
        border:none;
    }
    .center {
        margin: 0 auto 2rem;
    }
    .right {
        margin-left: auto;
        margin-right: 0;
    }

    .edit_links button {
        margin: 0 0rem 0.7rem;
    }
    .edit_links svg {
        width: 0.9rem;
        height: 0.9rem;
        margin-right: 0.3rem;
    }

</style>

