<template>
  <div class="recipe_container ">
    <div v-if="results.sintagi && results.sintagi.length > 0">
      <div class="container">
      <div class="recipe_header row">
         
        <!-- IMAGE -->
        <div class="col-sm-6">
          <div v-if="results.sintagi[0].central_media.length>0">
            <div class="central_img" v-if="!img_isEditing"  :style="{ backgroundImage: 'url(' + results.sintagi[0].central_media + ')' }">
            </div>
          </div>
          <div  v-else>
            <div class="central_img default_recipe_img no_photo_txt style-italic t-details-color" v-if="!img_isEditing">
                {{ $t('info.no_image') }}
            </div>
          </div>
          <div v-if="img_isEditing">
              <p class="subtext">{{ $t('subtetexts.media') }}</p>
              <input type="file" v-on:change="handleFileChange()" ref="central_media" name="central_media" id="central_media" class="text_input full_width_input img_input">

            </div>

          <div class="img_btn">
              <EditButton
                v-if="I_am_the_author"
                  :isEditing="img_isEditing"
                  :I_am_the_author="I_am_the_author"
                  align="right"
                  text="εικόνας"
                  border="no-border"
                  v-on:edit-pressed="img_isEditing = true"
                  v-on:save-pressed="updateRecipeData().then(data=>{img_isEditing = false})"
                />
          </div>
        </div>
        <div class="col-sm-6 ">
          <div class="first_info">

            <!-- TITLE -->
            <h1 v-if="!title_isEditing">{{results.sintagi[0].name}}</h1>
            <div v-if="title_isEditing">
              <input v-on="input_handlers" v-bind:class="{ error: checkFieldError('name')}" v-model="results.sintagi[0].name" type="text" name="name" class="text_input full_width_input name_input" maxlength="50" minlength="5" placeholder="Τίτλος συνταγής" >
            </div>
            <EditButton
              v-if="I_am_the_author"
              :isEditing="title_isEditing"
              :I_am_the_author="I_am_the_author"
              align="right"
              text="τίτλου"
              border="no-border"
              v-on:edit-pressed="title_isEditing = true"
              v-on:save-pressed="updateRecipeData().then(data=>{title_isEditing = false})"
            />

            <!-- AUTHOR AND DATE -->
            <p class="font-size-sm t-details-color"> <span class="user_link" @click="select_user($event)">{{results.user_data[0].username}}</span>   |  {{results.sintagi[0].created_at}}  </p>

            <!-- TAGS -->
            <div v-if="results.sintagi[0].tags.length>0" class="tags solid-border-top">
              <h6 class="t-details-color font-size-xs style-italic">{{ $t('recipe.details.tags') }}</h6>
              <div v-if="!tag_isEditing">
                <ul v-for="tag in results.sintagi[0].tags" :key="tag.id">
                  <li>
                    {{tag.text}}
                  </li>
                </ul>
              </div>
              <div v-if="tag_isEditing">
                  <vue-tags-input
                      v-model="tag"
                      :tags="results.sintagi[0].tags"
                      @tags-changed="newTags => results.sintagi[0].tags= newTags"
                      name="tags"
                      class="text_input full_width_input vue_tags"
                  />
              </div>
              <EditButton
                v-if="I_am_the_author"
                :isEditing="tag_isEditing"
                :I_am_the_author="I_am_the_author"
                align="right"
                text="ετικετών"
                border="no-border"
                v-on:edit-pressed="tag_isEditing = true"
                v-on:save-pressed="updateRecipeData().then(data=>{tag_isEditing = false})"
              />
            </div>
            <!-- TODO: DOWNLOAD RECIPE -->
            <!-- <button class="secondary_btn"> <img src="@/assets/download_icon.svg" class="icon_secondary_btn" alt=""/> κατέβασε την συνταγή</button> -->

            <!-- TODO: SHARE AT SOCIAL -->
            <!-- <div class="solid-border-top"></div>
            <ShareSocial/> -->

            <!-- USER RATING -->
            <div class="rating solid-border-top">
              <h6 class="t-details-color font-size-xs style-italic">{{ $t('my_rating') }}</h6>
              <div v-if="user.data">
               <svg v-for="num in maxRating" :key="num"
                  :class="starClassList[num-1]"
                  @click="ratingVote(num)"
                  @mouseover="hoverStar(num)">
                  <use href="@/assets/icon_sprite.svg#star_icon"/>
              </svg>
              <span class="t-details-color font-size-xs style-italic">( {{ user_rating }} )</span>
              </div>
              <div v-else>
                <router-link class="primary_link" :to="'/login?fwd=/recipes/'+results.sintagi[0].id">{{ $t('connect_to_rate') }}</router-link>
              </div>
            </div>

            <Reactions v-if="results.sintagi[0].id"
              :recipe_id="results.sintagi[0].id"
              :reactions="results.sintagi[0].reaction" 
             />

          </div>
        </div>
      </div>

      <!-- DETAILS - BIG NUMBERS -->
      <div class="recipe_details">
        <EditButton
          v-if="I_am_the_author"
          :isEditing="details_isEditing"
          :I_am_the_author="I_am_the_author"
          align="left"
          v-on:edit-pressed="details_isEditing = true"
          v-on:save-pressed="updateRecipeData().then(data=>{details_isEditing = false})"
        />
        <div class="row">
          <div class="col">
            <span>{{ $t('recipe.details.cooking_time') }}</span>
            <svg class="details_icons"><use href="@/assets/icon_sprite.svg#cooking_time"/></svg>
            <span v-if="!details_isEditing" class="big_numbers">{{results.sintagi[0].cooking_time}}'</span>
            <input v-if="details_isEditing" v-on="input_handlers" v-bind:class="{ error: checkFieldError('cooking_time')}" v-model="results.sintagi[0].cooking_time" type="number" name="cooking_time" class="text_input full_width_input big_numbers_input" step="5" min="0">
          </div>
          <div class="col">
            <span>{{ $t('recipe.details.waiting_time') }}</span>
            <svg class="details_icons"><use href="@/assets/icon_sprite.svg#waiting_time"/></svg>
            <span v-if="!details_isEditing" class="big_numbers">{{results.sintagi[0].waiting_time}}'</span>
            <input v-if="details_isEditing" v-on="input_handlers" v-bind:class="{ error: checkFieldError('waiting_time')}" v-model="results.sintagi[0].waiting_time" type="number" name="waiting_time" class="text_input full_width_input big_numbers_input" step="5"  min="0">
          </div>
          <div class="col">
            <span>{{ $t('recipe.details.portions') }}</span>
            <svg class="details_icons"><use href="@/assets/icon_sprite.svg#portions"/></svg>
            <span v-if="!details_isEditing" class="big_numbers">{{results.sintagi[0].portions}}</span>
            <input v-if="details_isEditing" v-on="input_handlers" v-bind:class="{ error: checkFieldError('portions')}" v-model="results.sintagi[0].portions" type="number" name="portions" class="text_input full_width_input big_numbers_input"  min="0">
          </div>
          <div class="col">
            <span>{{ $t('recipe.details.difficulty') }}</span>
            <svg class="details_icons"><use href="@/assets/icon_sprite.svg#difficulty"/></svg>
            <span v-if="!details_isEditing" class="big_numbers">{{results.sintagi[0].difficulty}}</span>
            <input v-if="details_isEditing" v-on="input_handlers" v-bind:class="{ error: checkFieldError('difficulty')}" v-model="results.sintagi[0].difficulty" type="number" name="difficulty" class="text_input full_width_input big_numbers_input"  min="1" max="3" >
          </div>
          <div class="col">
            <span>{{ $t('recipe.details.rating') }}</span>
            <svg class="details_icons"><use href="@/assets/icon_sprite.svg#star_rating"/></svg>
            <span class="big_numbers" >{{results.sintagi[0].rating.sum}}</span>

            <p class="rating_total_users font-size-xs t-details-color">{{ $tc('from_x_users', results.sintagi[0].rating.total_votes, { count: results.sintagi[0].rating.total_votes} ) }}</p>
          </div>
        </div>
      </div>
      <div class="recipe_main">

        <!-- DESCRIPTION -->
        <div v-if="results.sintagi[0].description || I_am_the_author" class="description">
          <h3>{{ $t('recipe.sections.intro') }}</h3>
          <EditButton
            v-if="I_am_the_author"
            :isEditing="description_isEditing"
            :I_am_the_author="I_am_the_author"
            align="center"
            text="πεδίου"
            v-on:edit-pressed="description_isEditing = true"
            v-on:save-pressed="updateRecipeData().then(data=>{description_isEditing = false})"
          />
          <div v-if="results.sintagi[0].description" >
            <p v-if='!description_isEditing'>{{results.sintagi[0].description}}</p>
          </div>
          <div v-else>
            <p v-if='!description_isEditing' class="t-details-color style-italic" style="text-align:center">{{ $t('recipe.intro.no') }}</p>
          </div>
          <div v-if='description_isEditing'>
            <p class="subtext"> {{ $t('subtexts.description')}}</p>
            <textarea rows="5" v-model="results.sintagi[0].description" name="description" id="description" class="text_input full_width_input"/>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-5 col-md-6 ingr_section" v-bind:class="{'col-lg-12 col-md-12': ingredient_isEditing ||  utensil_isEditing || steps_isEditing }">
            
            <!-- INGREDIENTS -->
            <div class="ingredients">
              <h3>{{ $t('recipe.sections.ingredients') }}</h3>
              <EditButton
                v-if="I_am_the_author"
                :isEditing="ingredient_isEditing"
                :I_am_the_author="I_am_the_author"
                align="center"
                v-on:edit-pressed="ingredient_isEditing = true"
                v-on:save-pressed="updateRecipeData().then(data=>{ingredient_isEditing = false})"
              />
              <div v-if="!ingredient_isEditing">
                <div  class="ingredient_line" v-for="ingredient in results.sintagi[0].ingredients" :key="ingredient.id || ingredient.idA" >
                  <p>
                    {{ingredient.quantity}} 
                    {{ingredient.units}} 
                    <!-- <span class="ingr_name"> {{ingredient.name}}</span>  -->
                    <InfoSign v-bind:ingredient_name="ingredient.name"/>
                    {{ingredient.details}}
                  </p>
                </div>
              </div>

              <div v-if='ingredient_isEditing'>
                <p class="subtext"> {{ $t('subtexts.ingredients') }}</p>
                <div ref="ingredients">
                    <div class="text_row" >
                        <div class="first_empty"> </div>
                        <div class="ing_name_text">{{ $t('recipe.ingredient.name') }}: <MandatorySign v-bind:mandatory="true"/></div>
                        <div class="ing_quantity_text">{{ $t('recipe.ingredient.quantity') }}: <MandatorySign v-bind:mandatory="true"/></div>
                        <div class="ing_units_text">{{ $t('recipe.ingredient.unit') }}: <MandatorySign v-bind:mandatory="true"/></div>
                        <div class="ing_details_text" >{{ $t('recipe.ingredient.details') }}: <MandatorySign v-bind:mandatory="false"/></div>
                    </div>
                    <NewIngredient
                      :ref="ingredient.id || ingredient.idA"
                      v-model="ingredients[index]"
                      v-on:remove="removeIngredient(index)"
                      v-for="(ingredient,index) in ingredients"
                      :key="ingredient.id || ingredient.idA"
                      :id="ingredient.id || ingredient.idA"
                      :given_name="ingredient.name"
                      :given_units="ingredient.units"
                      :given_details="ingredient.details"
                      :given_quantity="ingredient.quantity"
                      :errors="errors[ingredient.id || ingredient.idA] || null"
                    />
                </div>

                <button type="button" v-on:click="addNewIngredient" class="add_btn primary_btn ">+
                  <span class="tooltip_add" >{{ $t('recipe.ingredient.add') }}</span>
                </button>
              </div>
            </div>
            
            <!-- UTENSILS -->
             <div class="utensils" v-if="results.sintagi[0].utensils">
              <h3>{{ $t('recipe.sections.utensils') }}</h3>
              <EditButton
                v-if="I_am_the_author"
                :isEditing="utensil_isEditing"
                :I_am_the_author="I_am_the_author"
                align="center"
                v-on:edit-pressed="utensil_isEditing = true"
                v-on:save-pressed="updateRecipeData().then(data=>{utensil_isEditing = false})"
              />
              <div v-if="!utensil_isEditing">
                <div v-for="(utensil,index) in results.sintagi[0].utensils" :key="index" >
                  <p>
                    {{utensil.quantity}} <span class="ingr_name"> {{utensil.name}}</span> {{utensil.details}}
                  </p>
                </div>
              </div>
              <div v-if="utensil_isEditing">
                <div class="text_row" >
                    <div class="first_empty"> </div>
                    <div class="u_name_text">{{ $t('recipe.utensil.name') }}:        <MandatorySign v-bind:mandatory="false"/></div>
                    <div class="u_quantity_text">{{ $t('recipe.utensil.quantity') }}:  <MandatorySign v-bind:mandatory="false"/></div>
                    <div class="u_details_text" >{{ $t('recipe.utensil.details') }}: <MandatorySign v-bind:mandatory="false"/></div>
                </div>
                <NewUtensil
                  v-on:remove="removeUtensil(index)"
                  v-for="(utensil,index) in results.sintagi[0].utensils"
                  :key="'utensil_'+index"
                  :given_quantity="utensil.quantity"
                  :given_name="utensil.name"
                  :given_details="utensil.details"
                  v-model="utensils[index]"
                />

                <button type="button" v-on:click="addNewUtensil" class="primary_btn add_btn">+
                    <span class="tooltip_add">{{ $t('recipe.utensil.add') }}</span>
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-md-6" v-bind:class="{'col-lg-12 col-md-12': ingredient_isEditing ||  utensil_isEditing || steps_isEditing }">
            
            <!-- STEPS -->
            <div class="steps">
              <h3>{{ $t('recipe.sections.steps') }}</h3>
              <EditButton
                v-if="I_am_the_author"
                :isEditing="steps_isEditing"
                :I_am_the_author="I_am_the_author"
                align="center"
                v-on:edit-pressed="steps_isEditing = true"
                v-on:save-pressed="updateRecipeData().then(data=>{steps_isEditing = false})"
              />
                <div v-if="!steps_isEditing">
                  <ol type="A"  >
                    <div v-for="step in steps" :key="step.id" >
                      <li><h5>{{step.description}}</h5></li>
                      <ol class="substeps_list">
                        <div v-for="substep in step.substeps" :key="substep.id" >
                          <li>{{substep.description}}</li>
                          <img v-if="substep.img" :src="substep.img" class="step_img">
                        </div>
                      </ol>
                    </div>
                  </ol>
                </div>
                <div v-if="steps_isEditing">
                  <p class="subtext"> {{ $t('subtexts.steps') }}</p>
                  <div ref="steps">
                      <NewStep
                      :ref="step.id || step.idA"
                      v-on:remove="removeStep(index)"
                      v-for="(step,index) in results.sintagi[0].steps"
                      v-model="steps[index]"
                      :key="step.id || step.idA"
                      :given_id="step.id || step.idA"
                      :given_description="step.description"
                      :given_substeps="step.substeps"
                      :given_substeps_counter="step.substeps_counter"
                      :shouldAddNewSubstep="step.isNew || false"
                      />
                  </div>

                  <button type="button" v-on:click="addNewStep" class="primary_btn add_btn">+
                    <span class="tooltip_add">{{ $t('recipe.steps.add') }}</span>
                  </button>

                </div>

            </div>
          </div>
        </div>

        <!-- TIPS -->
        <div v-if="results.sintagi[0].tips || I_am_the_author" class="tips">
            <h3>{{ $t('recipe.sections.tips') }}</h3>
          <EditButton
            v-if="I_am_the_author"
            :isEditing="tips_isEditing"
            :I_am_the_author="I_am_the_author"
            align="center"
            text="πεδίου"
            v-on:edit-pressed="tips_isEditing = true"
            v-on:save-pressed="updateRecipeData().then(data=>{tips_isEditing = false})"
          />
          <div v-if="results.sintagi[0].tips">
            <p v-if="!tips_isEditing">{{results.sintagi[0].tips}}</p>
          </div>
          <div v-else>
            <p v-if="!tips_isEditing" class="t-details-color style-italic" style="text-align:center">{{ $t('recipe.tips.no') }}</p>
          </div>
          <div v-if='tips_isEditing'>
            <p class="subtext"> {{ $t('subtexts.tips') }}</p>
            <textarea rows="3" v-model="results.sintagi[0].tips" name="tips" id="tips" class="text_input full_width_input tips_input"/>
          </div>
        </div>
      </div>
      <!-- TODO: SHARE AT SOCIAL -->
      <!-- <div class="recipe_footer">
        <ShareSocial/>
      </div> -->
      </div>
      <!-- <IngredientQuestion :ingredients="results.sintagi[0].ingredients" v-if="user.data" /> -->
      <IngredientQuestion :recipe_id="results.sintagi[0].id" v-if="user.data" />
    </div>

    <div v-if="error">
      <h3>{{error}}</h3>
    </div>

    <UnsavedDataPopup
      v-if = "cached_recipe_diff"
      :diff = "cached_recipe_diff"
      v-on:edit-name="title_isEditing = true"
      v-on:edit-description="description_isEditing = true"
      v-on:edit-details="details_isEditing = true"
      v-on:edit-ingredients="ingredients_isEditing = true"
      v-on:edit-utensils="utensils_isEditing = true"
      v-on:edit-steps="steps_isEditing = true"
      v-on:edit-tips="tips_isEditing = true"
      v-on:edit-tags="tags_isEditing = true"

    />

  </div>

</template>

<script>

import InfoSign from './extras/Info_sign.vue'
import MandatorySign from './extras/Mandatory_sign.vue'
import NewUtensil from './Recipe_form/New_utensil.vue'
import NewIngredient from './Recipe_form/New_ingredient.vue'
import NewStep from './Recipe_form/New_step.vue'

import VueTagsInput from '@johmun/vue-tags-input';
import EditButton from './extras/Edit_button.vue';
import IngredientQuestion from './extras/Ingredient_question.vue';
import Reactions from './extras/Reactions.vue';
import UnsavedDataPopup from './extras/UnsavedDatapopup.vue';

import Vue from 'vue'
import { mapGetters } from "vuex";

export default {

  name: 'RecipeInternal',
  components: {
    InfoSign,
    MandatorySign,
    NewIngredient,
    NewUtensil,
    NewStep,
    VueTagsInput,
    EditButton,
    IngredientQuestion,
    Reactions,
    UnsavedDataPopup,
  },

  data(){
    return {
      input_handlers:{
        propertychange:this.handleInputChanges,
        change:this.handleInputChanges,
        click:this.handleInputChanges,
        keyup:this.handleInputChanges,
        input:this.handleInputChanges,
        paste:this.handleInputChanges
      },
      cached_recipe_diff:false,
      tag:"",
      results:{
        sintagi:[],
        user_data:[]

      },
      central_media:"",
      errors:[],
      result: "",
      error:false,
      img_isEditing:false,
      title_isEditing:false,
      details_isEditing:false,
      description_isEditing:false,
      ingredient_isEditing:false,
      utensil_isEditing:false,
      tag_isEditing:false,
      tips_isEditing:false,
      steps_isEditing:false,
      ingredients:[],
      utensils:[],
      steps:[],
      ingredients_counter:0,

      user_rating: this.$t('you_have_not_rated'),
      rating: NaN,
      maxRating: 5,

      I_am_the_author:false,

    }
  },

  computed: {
    // map `this.user` to `this.$store.getters.user`
      ...mapGetters({
        user: "user"
      }),
    starClassList () {
      const arr = [];
      for (let i=0; i < this.maxRating; i++) {
        const classes = ["star"]
        if (i <= this.rating) {
          classes.push('selected_star')
        }
        arr.push(classes)
      }
      return arr;
    },
    nameList() {
      let list = this.results.sintagi[0].ingredients.map((entry) => {
        return entry.name;
      })
      console.log(list);
      return list;
    },
    // ratingSum () {
    //   if (this.results.sintagi[0].rating.sum) {
    //     return this.results.sintagi[0].rating.sum.toFixed(1);
    //   } else {
    //     return "-";
    //   }
    //}

  },
  watch: {
      // '$route.params.id': function () {
      //   console.log("router getter ran")

      //   this.getRecipe();

      // },
      "$store.getters.user.data": function () {
            if(this.results.sintagi && this.results.sintagi[0]){
              if ((this.user.data.profile.email == this.results.sintagi[0].author) || (this.user.data.profile.admin)){
                this.I_am_the_author = true;
              }
            }

            this.UserRatingVote();
        }
  },
  async mounted(){
    this.getRecipe();
  },
  methods:{
    handleInputChanges: function(event){
      this.cookingCache_store(this.results.sintagi[0])
    },
    ingredient_stopEditing: function(){
      for(let ingredient_index in this.results.sintagi[0].ingredients){
        let ingredient = this.results.sintagi[0].ingredients[ingredient_index]
        this.results.sintagi[0].ingredients[ingredient_index] = this.$refs[ingredient.idA][0].$data
      }
      this.ingredient_isEditing = false;
    },
    utensil_stopEditing:function() {
      for(let utensil_index in this.results.sintagi[0].utensils){
        let utensil = this.results.sintagi[0].utensils[utensil_index]
        this.results.sintagi[0].utensils[utensil_index] = this.$refs[utensil.idA][0].$data
      }
      this.utensil_isEditing = false;
    },
    steps_stopEditing:function() {
      this.steps_isEditing = false;
    },
    formatDate:function(date){
      let date_object = new Date(date);
      return date_object.getDate()+"/"+(parseInt(date_object.getMonth())+1)+"/"+date_object.getFullYear();
    },
    getRecipe: function() {
        this.results = [];
        let image_path;
        fetch(process.env.VUE_APP_API+`/recipes?id=`+this.$route.params.id)
          .then(res => res.json())
          .then(res => {
            if(res.error){
              this.error = res.error;
            }else{
              if(res.sintagi && res.sintagi.length < 1){
                this.error = "No results";
              }else{
                this.results = res;
                if(this.results.sintagi[0].created_at){
                  if(this.results.sintagi[0].central_media){
                    image_path = this.results.sintagi[0].central_media.split("https://cookingapi.handbook.gr")[1]
                    this.results.sintagi[0].central_media = process.env.VUE_APP_API + image_path
                  }
                  for(let step of this.results.sintagi[0].steps){
                    if(step.img){
                      image_path = step.img.split("https://cookingapi.handbook.gr")[1]
                      step.img = process.env.VUE_APP_API + image_path
                    }
                    for(let substep of step.substeps){
                      if(substep.img){
                        image_path = substep.img.split("https://cookingapi.handbook.gr")[1]
                        substep.img = process.env.VUE_APP_API + image_path
                      }
                    }
                  }
                  this.results.sintagi[0].created_at = this.formatDate(this.results.sintagi[0].created_at)
                  this.ingredients = this.results.sintagi[0].ingredients;
                  this.utensils = this.results.sintagi[0].utensils;
                  this.steps = this.results.sintagi[0].steps;
                  this.rating=this.results.sintagi[0].rating;
                  // this.starClassList();

                  if (this.user.data && ((this.user.data.profile.email == this.results.sintagi[0].author) || (this.user.data.profile.admin))){
                    this.I_am_the_author = true;
                  }

                  //load/check cache
                  this.cached_recipe_diff = this.cookingCache_deepdiff(this.results.sintagi[0])
                }
              }
            }
          });
    },

    ratingVote:function(rating_value) {
      fetch(process.env.VUE_APP_API+`/recipes/rating`,{
            method: "post",
            headers: {
                "TOKEN":JSON.stringify(this.user.data.token),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              "rating":{
                  "recipe_id":this.results.sintagi[0].id,
                  "value":rating_value
              }
            })
        }).then(res => res.json())
          .then(res => {
              //TODO: Error Validation
              if(res.error){
                  this.errors = res.error
              }
              else {
                this.$router.go();

              }
          });
    },
    UserRatingVote:function() {
      if(this.$route.params.id){
          fetch(process.env.VUE_APP_API+`/recipes/user_rating`,{
                method: "post",
                headers: {
                    "TOKEN":JSON.stringify(this.user.data.token),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  "rating":{
                      "recipe_id":this.$route.params.id
                  }
                })
            }).then(res => res.json())
              .then(res => {
                  //TODO: Error Validation
                  if(res.error){
                      this.errors = res.error;
                      console.log(res.error)
                  }
                  else {
                    this.user_rating = res.value;
                    this.rating = res.value - 1;
                  }
          });
      }
    },
    updateRecipeData() {
      return new Promise((resolve,reject)=>{
        if(this.ingredient_isEditing){
          this.results.sintagi[0].ingredients = this.ingredients;
          this.ingredient_isEditing = false;
        }
        if(this.utensil_isEditing){
          this.results.sintagi[0].utensils = this.utensils;
          this.utensil_isEditing = false;
        }

        //get the data for all steps
        if(this.steps_isEditing){
          this.results.sintagi[0].steps = this.steps;

        }
        this.results.sintagi[0].I_was_the_author = this.I_am_the_author;
        var recipe_form = new FormData();
        let step_counter =0;
        for(let step of this.results.sintagi[0].steps){
          for(let substep of step.substeps){
            if(substep.img){
              recipe_form.append('step_'+step_counter+'_substep_'+substep.id+'_media', substep.img)
            }
          }
          step_counter++;
        }
        recipe_form.append('central_media',this.central_media);
        recipe_form.append('recipe', JSON.stringify(this.results.sintagi[0]))
        fetch(process.env.VUE_APP_API+`/recipes/update/`+this.results.sintagi[0].id,{
              method: "post",
              headers: {
                  "TOKEN":JSON.stringify(this.user.data.token),
              },
              body: recipe_form
          }).then(res => res.json())
            .then(res => {
                //TODO: Error Validation
                if(res.error){
                    this.errors = res.error
                    resolve();
                }
                else {
                  this.results.sintagi[0] = res.recipe;
                  this.results.sintagi[0].created_at = this.formatDate(this.results.sintagi[0].created_at)

                  this.ingredients = res.recipe.ingredients;
                  this.utensils =  res.recipe.utensils;
                  this.steps = res.recipe.steps
                  this.central_media="";
                }
                resolve();
            });
      })
    },
    addNewUtensil() {
      this.utensils.push({})
    },
    removeUtensil(u_id) {
      Vue.delete(this.utensils,u_id);
    },
    addNewIngredient() {
      let a_ingredient = {
          idA:"ingredient_"+this.ingredients.length,
          data:{}
      }

      this.ingredients.push(a_ingredient)

    },
    removeIngredient(ingr_id) {
        Vue.delete(this.ingredients, ingr_id);
    },
    addNewStep() {
      let a_step = {
          id:"step_"+ this.steps.length,
          isNew:true
      }
      this.steps.push(a_step);
    },
    removeStep(st_id) {
        Vue.delete(this.steps,st_id);
    },

    checkFieldError(field_name){
      return this.errors[field_name] != null;
    },

    hoverStar(num) {
      this.rating = num - 1;
    },
    handleFileChange(){
      this.central_media = this.$refs.central_media.files[0];
    },
      select_user:function(event){
        // this.$router.push({ path: '/user/'+event.currentTarget.id})
        this.$router.push({ path: '/user/'+this.results.user_data[0].username})
    }
  },
}
</script>

<style lang="scss" scoped>

.reaction {
  display: inline-flex;
  width: fit-content;
  margin: 0.2rem;
  border-radius: 50px;
  transition: all 0.2s ease;
  &:hover {
    box-shadow: 0 2px 5px rgba(51, 51, 51, 0.336);
  }
  &.voted {
    svg {
      fill:red;
    }
  }

}

  .recipe_container{
    margin:auto;
    text-align: left;
    max-width: 980px;
    width: 100%;
  }
  h1 {
    margin-top: 0.6rem;
  }
  h2 {
    margin-bottom: 1rem;
  }
  h3 {
    text-align: center;
    margin-bottom: 1rem;
  }
  .description {
    /* padding: 0 15px 1.5rem; */
    /* border-bottom: var(--line-dashed); */
    margin-bottom: 3rem;
  }
  .recipe_header {
    min-height: 280px;
  }

  .central_img{
    max-height: 350px;
    min-height: 280px;
    border-radius: 0 0 10px 10px;
  }

  .central_img img {
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
    display: block;
  }
  .no_photo_txt{
    padding-top: 9rem;
    text-align: center;


  }
  .first_info {
    margin: 0.2rem 0.8rem;
  }
  .first_info svg {
    width: 1rem;
    height: 1rem;
    fill: var(--color-grey-1);
    margin-right: 0.5rem;
  }
  .first_info ul {
    list-style: none;
    display: inline-block;
    padding: 0px 2px;
    margin-bottom: 4px;
  }
  .first_info li {
      margin-bottom: 0rem;
      background-color: var(--color-grey-1);
      padding: 2px 7px;
      border-radius: 4px;
      font-size: 0.8rem;
  }
  .user_link:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  .tags {
    /* display: inline-flex; */
    width:100%;
    color: white;
  }
  /* .tags div {
    width:100%;
  } */

  .recipe_details {
    margin: 2rem 15px;
    padding: 1rem;
    text-align: center;
    border-top: var(--line-dashed);
    border-bottom: var(--line-dashed);
  }
  .recipe_details .row div {
    display: grid;
    padding: 0;
    margin-bottom: 1.5rem;
    min-width: 90px;
  }
  .rating {
    display: flex;
  }
  .rating h6 {
        margin: 0.4rem 1rem 0 0rem;
  }
  .rating svg{
    width: 1rem;
    height: 1rem;
    margin-right: 0.2rem;
    cursor: pointer;
  }
  .selected_star {
    fill: var(--color-primary) !important;
  }

  .rating_total_users {
    text-align: center;
    position: absolute;
    bottom: -2.5rem;
    width: 100%;
  }
  .big_numbers {
    font-size: 2rem;
    font-weight: 100;
    margin-top: 0.6rem;
  }
  .details_icons {
    margin: 1rem auto 0;
    fill: var(--color-grey-1);
    opacity:0.2;
    height: 3.6rem;
    width: 3.6rem;
    /* z-index: -1; */
    position: relative;
    top: -1rem;
    margin-bottom: -7rem;
  }
  .recipe_details span {
      min-height: 2.5rem;
      line-height: 1.2;
      z-index: 1;
  }
  .recipe_main {
      margin-left: 15px;
      margin-right: 15px;
  }
  .icon, svg {
    height: 2rem;
    width: 2rem;
    fill: black;
  }
  .ingr_name{
    font-weight: 600;
  }
  .ingredients {
    padding-right: 0.5rem;
  }
  .ingr_section {
    border-right: var(--line-dashed);
  }

  .ingr_section.col-lg-12,  .ingr_section.col-md-12 {
    border-right: none;
  }
  .utensils {
    margin-top:3rem;
  }

  .steps {
    /* margin-left: 0.5rem; */
    padding-left:0.5rem;
  }
  ol {
    padding-left: 0.8rem;
  }
  li {
    margin-bottom: 1rem;
  }
  ol li::marker {
    font-size: 1rem;
    font-weight: 600;
    color: var (--color-darker-blue);
  }
  .step_img {
    margin: auto;
    display: block;
    max-width: 100%;
    max-height: 100%;
  }

  .recipe_footer {
    min-height: 50px;
    border-top: var(--line-dashed);
    padding-top: 0.5rem;
    margin-top: 0.5rem;
  }
  .share {
    display: inline-flex;
    margin: auto;
    float: right;
  }
  .tips {
    border: var(--line-dashed);
    border-radius: 10px;
    margin: 2rem 0rem;
    padding: 2rem;
  }
  .tips p {
    font-style: italic;
  }

  /*   EDIT   */

  .edit_btn, .save_btn {
    width: 0.9rem;
    height: 0.9rem;
    display: block;
    float: right;
  }
  .edit_btn {
    fill:var(--color-grey-1);
  }
  .edit_btn:hover {
    fill:var(--color-secondary);
  }
  .save_btn {
    fill:goldenrod;
  }
  .save_btn:hover {
    fill:darkorange;
  }
  .name_input{
      width: 100%;
      font-size: 2rem;
      padding: 0.6rem 0.5rem;
  }
  .big_numbers_input {
      width: 80px;
      margin: 0.6rem auto;
      padding: 0.2rem;
      font-size: 1.8rem;
      text-align: center;
      background-color: #ffffff94;
  }
  .tips_input{
    width: 100%;
  }
  .text_row {
    display: flex;
  }
  .img_input{
    display: -webkit-box;
  }
  @media (max-width: 767.98px) {
    .ingr_section {
      border-right: none;
      border-bottom: var(--line-dashed);
      padding-bottom: 2rem;
      margin-bottom: 2rem;
    }
  }
</style>
