<template>
    <footer>
        <a href="/"><img class="logo_img" src="@/assets/cooking_logo.svg" alt=""></a>
        <div>
            <ul class="navigation">
                <li>
                    <router-link :to="{ name: 'Search'}">Συνταγές</router-link>
                </li>
                <li>
                    <router-link to="/recipe-form">Νέα Συνταγή</router-link>
                </li>
                <li>
                    <router-link to="/about-us">Σχετικά με εμάς</router-link>
                </li>
                <li>
                    <router-link to="/contactus">Επικοινωνία</router-link>
                </li>
                <li>
                    <router-link to="/login" v-if="user && !user.loggedIn">Εγγραφή / Σύνδεση</router-link>
                    <router-link to="/user" v-if="user && user.loggedIn"> Το προφίλ μου </router-link>
                </li>
                <li>
                    <a target="_blank" href="https://www.facebook.com/cookinghandbookgr"> 
                        <svg class="social_img"><use href="@/assets/icon_sprite.svg#fb_icon"/></svg>
                    </a>

                </li>

            </ul>
        </div>
    </footer>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    components: {
    },
    name: 'Footer',
    computed: {
        // map `this.user` to `this.$store.getters.user`
        ...mapGetters({
            user: "user"
        })
    },

  }
</script>

<style scoped>

</style>    
