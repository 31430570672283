<!--
this components is used when there is an INPUT that has AUTOCOMPLETE function.
we call it like this:

<InputList 
    data_url="/..."
    v-model="XX"                                    -> input name
    :inputValue="XX"                                -> input value
    v-bind:class="{ error: checkFieldError('XX')}"  -> if exists
    :placeholder="..."                              -> if exists
/>

-->

<template>
    <div v-click-outside="hide_list">
        <input  type="text" 
                class="text_input"
                v-model="input_value"
                autocomplete="off"
                v-on:input="updateValue($event.target.value)" 
                @focus="autocompleteFlag=true" 
                v-bind:placeholder="$props.placeholder || ''"
        >
                
        <div class="autocomplete-list" v-if="autocompleteFlag && filteredItems.length>0">
            <ul >
                <li v-for="filteredItem in filteredItems.slice(0, 10)" :key="filteredItem.text" @click="select_from_list(filteredItem.text)" >
                    {{ filteredItem.text }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import vClickOutside from 'v-click-outside'


export default {
    
    name:"InputList",
    props: [ 
        'inputValue',
        'data_url',
        "placeholder"
    ],
    directives: {
      clickOutside: vClickOutside.directive
    },
    data() {
        return {
            input_value:this.$props.inputValue || "",
            autocompleteFlag:false,
            autocompleteIngredients:[],
        }
    },
    beforeMount() {
        this.getData(this.$props.data_url);
    },
    watch: {
        data_url: function (val) {
            this.getData(this.$props.data_url);
        }
    },
    computed: {
        filteredItems() {
            if(this.input_value && this.input_value != ""){
                return this.autocompleteIngredients.filter(i => {
                    return i.text.toLowerCase().indexOf(this.input_value.toLowerCase()) !== -1;
                }) || this.autocompleteIngredients;
            }else{
                return this.autocompleteIngredients;
            }
            
        },
        
    },
    methods: {
        select_from_list: function(value){
            this.input_value=value;
            this.hide_list();
            this.updateValue(value);
        },
        hide_list: function(event){
            this.autocompleteFlag=false;
        },
        updateValue: function (value) {
            this.input_value = value.toLowerCase()
            this.$emit('input', this.input_value.trim())
        },
        getData: function(data_url){
            fetch(process.env.VUE_APP_API+data_url)
                .then(res => res.json())
                .then(res => {
                    res = res.map((entry)=>{
                        return {text:entry}
                    })
                    this.autocompleteIngredients = res;
                })
        },

    }
    
}
</script>

<style scoped>
    div:first-child {
        padding: 0 !important;
        margin: 0 !important;
        width: auto !important;
    }

    .autocomplete-list {
        position: absolute;
        margin-top: 4rem;
    }
    .autocomplete-list ul {
        width: 200px;
        z-index: 9;
        position: absolute;
        display: inline-block;
        background-color: white;
        border: 1px solid;
        list-style: none;
        padding: 0;
        border-radius: 6px;
        border: 1px solid var(--color-grey-1);
    }
    .dark .autocomplete-list ul {
        background-color: var(--color-bg-primary-dark);
        color: var(--color-text-primary-dark);
        border: 2px solid var(--color-text-primary);
    }
    .autocomplete-list li:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        padding-top: 0.6rem;
    }
    .autocomplete-list li:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        padding-bottom: 0.6rem;
    }
    .autocomplete-list li {
        padding: 0.3rem 0.8rem;
        cursor: pointer;
    }
    .autocomplete-list li:hover {
        background-color: #ececec;
    }
    .dark .autocomplete-list li:hover {
        background-color: var(--color-text-primary);
    }
    .error {
        border:none !important;
        /* border: 3px solid #00ff00 !important; */
    }
    .error input {
            border: 3px solid var(--color-red) !important;
    }
    input {
        width: 100%;
    }

</style>