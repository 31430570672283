<template>
  <div class="container medium_container">
    <h1>{{ $t('about_us.title') }}</h1>
    <div class="content">
      <p class="text-left" v-html="$t('about_us.intro')">
        <!-- {{ $t('about_us.intro') }} -->
      </p>


      
      
      <router-link to="/contactus" class="primary_btn add_recipe">Επικοινωνία</router-link>


    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutUs',
};
</script>
