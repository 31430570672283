<template>
<div class="div_sign" v-bind:class="[ {mandatory_field: mandatory}, {optional_field:!mandatory}]"> 
    <!-- <span class="sign">* -->
    <svg class="sign"><use href="@/assets/icon_sprite.svg#asterisk_icon"/>
    </svg>   
    <span class="text" >{{text}}</span>

    <!-- </span> -->
</div>

</template>


<script>
export default {
    name:'MandatoryField',
    props:[
        'mandatory'
    ],
    data() {
        return {
            mandatory_text:"Υποχρεωτικό",
            optional_text:"Προαιρετικό"
        }
    },
    computed: {
        text: function() {
            if (this.mandatory) {
                return this.mandatory_text;
            } 
            else {
                return this.optional_text;
            }
        }
    }
    
}
</script>

<style scoped>

    .div_sign {
        display: inline !important;
        /* margin-left: 0.2rem; */
        position: relative;
        top: -6px;
    }
    .div_sign * {
        background-color: #ffffffdd;
    }
    .dark .div_sign * {
        background-color: var(--color-bg-primary-dark);
    }
    .mandatory_field * {
        color: var(--color-red);
        fill: var(--color-red);
    }
    .optional_field {
        display: none !important;
    }
    .optional_field * {
        color: var(--color-grey-2);
        fill: var(--color-grey-2);
        /* background-color: #ffffffdd; */
    }
    .mandatory_field:hover .sign {
        border: 1px solid var(--color-red);

    }
    .optional_field:hover .sign {
        border: 1px solid var(--color-grey-2);
    }

    .sign {
        border-radius: 20px;
        padding: 3px;
        position: relative;
        width: 16px;
        height: 16px;
    }
    .text {
        font-size: 0.9rem;
        display: block;
        position: absolute;
        padding: 3px 8px;
        border-radius: 20px;
        top: 0;
        left: 20px;
        transition: all 0.2s ease;
        z-index: 1;
        visibility: hidden;
        opacity: 0;
    }

    .div_sign:hover .text {
        visibility: visible;
        opacity:1;
    }




</style>