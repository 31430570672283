<template>
    <div class=" socials">
        <button @click="facebookLogin" class="primary_btn fb_btn">
        <svg class="details_icons"><use href="@/assets/icon_sprite.svg#fb_icon"/></svg>   
        Facebook login
        </button>
        <button @click="googleLogin" class="primary_btn gg_btn">
        <svg class="details_icons"><use href="@/assets/icon_sprite.svg#google_icon"/></svg>   
        Google login
        </button>
    </div>
</template>

<script>
import firebase from "firebase";


export default {
    name: 'SocialLoginButtons',

    props: [ 
        'doNotRedirect',
    ],

    methods:{
    googleLogin(){
      var provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithPopup(provider).then((result)=> {
        //var token = result.credential.accessToken;
        //var user = result.user;
        const analytics = firebase.analytics()
        analytics.logEvent("user_logged_in_using_google")
        if(this.$props.doNotRedirect != "true"){
          if(this.$route.query.fwd){
            this.$router.push(this.$route.query.fwd)
          }else{
            this.$router.push({ path: '/'});
            // this.$router.replace({ name: "PublicProfile" });
          }
        }
      }).catch(function(err) {
          this.error = err.message;
      });
    },
    facebookLogin(){
      var provider = new firebase.auth.FacebookAuthProvider();
      firebase.auth().signInWithPopup(provider).then((result)=> {
        //var token = result.credential.accessToken;
        //var user = result.user;
        const analytics = firebase.analytics()
        analytics.logEvent("user_logged_in_using_facebook")
        if(this.$props.doNotRedirect != "true"){
          if(this.$route.query.fwd){
            this.$router.push(this.$route.query.fwd)
          }else{
            // this.$router.replace({ name: "PublicProfile" });
            this.$router.push({ path: '/'});

          }
        }
      }).catch(function(err) {
          this.error = err.message;
      });
    },
    submit() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then(() => {
          const analytics = firebase.analytics()
          analytics.logEvent("user_logged_in_using_emailAndPassword")
          if(this.$props.doNotRedirect != "true"){
            if(this.$route.query.fwd){
              this.$router.push(this.$route.query.fwd)
            }else{
              // this.$router.replace({ name: "PublicProfile" });
              this.$router.push({ path: '/'});

            }
          }
        })
        .catch(err => {
          this.error = err.message;
      });
    }
  }
    
}
</script>

<style scoped>

    .socials {
        margin-bottom: 2em;
        padding: 0.5rem;
    }
    .socials * {
        width: 100%;
        margin-bottom: 2rem;
        margin-top: 2rem;
    }
    .socials svg {
        width: 1.4rem;
        height: 1.4rem;
        margin: 0.2rem;
        fill: white;
    }
    .fb_btn {
        background-color: #0099ff;
    }
    .gg_btn {
        background-color: var(--color-red); 
    }
</style>