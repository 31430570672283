import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import LayoutPlugin	 from 'bootstrap-vue'
import vClickOutside from 'v-click-outside'
import i18n from "./i18n";
import cookieconsent from 'vue-cookieconsent-component'
import store from "./store";

import deepDiffMapper from './deepDiffMapper'

// import './assets/css/bootstrap.min.css'
import * as firebase from "firebase";

import Routes from './routes';
import { marked } from "marked";

Vue.component('cookie-consent', cookieconsent)

Vue.use(LayoutPlugin)
Vue.use(vClickOutside)


const configOptions = {
  apiKey: "AIzaSyD6mb2izecyuIinYYMpPrEcx0ETg0F0nho",
  authDomain: "syntages-351f3.firebaseapp.com",
  databaseURL: "https://syntages-351f3.firebaseio.com",
  projectId: "syntages-351f3",
  storageBucket: "syntages-351f3.appspot.com",
  messagingSenderId: "798223363384",
  appId: "1:798223363384:web:fd6469cb31c926e026e5cd",
  measurementId: "G-VKZZ4LSK49"
};

firebase.initializeApp(configOptions);

Vue.use(VueRouter)

const router = new VueRouter({
    routes: Routes,
    mode: 'history',
    scrollBehavior (to, from, savedPosition) {
      return { x: 0, y: 0, behavior: 'smooth' }
    }
})

firebase.auth().onAuthStateChanged(async user => {
  if(user){
    let token = await firebase.auth().currentUser.getIdToken(true)
    user.token= token;


    //fetch the profile data for this user from the backend
    fetch(process.env.VUE_APP_API+`/user/me`, { method: 'GET', headers:{"TOKEN":JSON.stringify(user.token)}})
      .then(res => res.json())
      .then(res => {
        if(res.error){
          console.log("error while trying to fetch user profile from the backend", res.error)
          store.dispatch("fetchUser", user);
        }else{
          if(res.length > 0){
            user.profile = res[0]
          }
          store.dispatch("fetchUser", user);

        }
      })
  }
});

function load_cookingCache(){
  let current_cache = localStorage.getItem("cooking_temp_storage")
  if(current_cache){
    try{
      current_cache = JSON.parse(current_cache)
    }catch(error){
      current_cache = {}
    }
  }else{
    current_cache = {}
  }

  return current_cache
}

Vue.mixin({
  methods: {
      md: function (input) {
          return marked (input);
      },
      cookingCache_store: function(sintagi){
        let current_cache = load_cookingCache()
        current_cache[sintagi.id] = sintagi 
        localStorage.setItem("cooking_temp_storage", JSON.stringify(current_cache))
      },
      cookingCache_deepdiff: function(sintagi){
        let current_cache = load_cookingCache()
        if(current_cache[sintagi.id]){
          let diff = deepDiffMapper.map(current_cache[sintagi.id],sintagi);         
          
          console.log(diff)
          return diff
        }else{
          return false;
        }
      },
      cookingCache_get: function(sintagi_id){
        let current_cache = load_cookingCache()
        return current_cache[sintagi_id] || false
      }
  },
})

 new Vue({
   el: '#app',
   store,
   i18n,
   render: h => h(App),
   router: router,
 })