<template>
    <div class="reactions solid-border-top">
        <div class="yummy_reaction reaction" @click="reactionClicked('yummy')" v-bind:class="{ voted: my_reactions['yummy']}">
            <svg class="default_icon reaction_icon" ><use href="@/assets/icon_sprite.svg#yummy"/></svg> 
            <span v-if="reaction && reaction['yummy']">
            {{reaction['yummy']}}
            </span>
            <span v-else>0</span>
        </div>
        <div class="gourmet_reaction reaction" @click="reactionClicked('gourmet')" v-bind:class="{ voted: my_reactions['gourmet']}">
            <svg class="default_icon reaction_icon" ><use href="@/assets/icon_sprite.svg#gourmet"/></svg> 
            <span v-if="reaction && reaction['gourmet']">
            {{reaction['gourmet']}}
            </span>
            <span v-else>0</span>
        </div>
        <div class="wellwritten_reaction reaction" @click="reactionClicked('wellwritten')" v-bind:class="{ voted: my_reactions['wellwritten']}">
            <svg class="default_icon reaction_icon" ><use href="@/assets/icon_sprite.svg#wellwritten"/></svg> 
            <span v-if="reaction && reaction['wellwritten']">
            {{reaction['wellwritten']}}
            </span>
            <span v-else>0</span>
        </div>

                
        <ActionModal v-if="show_login"
            title="Δεν έχετε συνδεθεί"
            description="Για να αντιδράσετε στην συνταγή, πρέπει να συνδεθείς"
            action_btn="Σύνδεση"
            back_btn= "Ακύρωση"
            btn_type="action_btn"
            v-on:accept-action="gotoLogin"
            v-on:decline-action="show_login = false"
        />


    </div> 

    
</template>

<script>

import { mapGetters } from "vuex";
import ActionModal from './Action_modal.vue';


export default {
    name: 'Reactions',
    data() {
        return {
            my_reactions: {
                yummy:false,
                gourmet:false,
                wellwritten:false,
            },
            reaction:{},
            show_login:false,
        }
    },
    props: [
        'recipe_id',
        'reactions',
        // 'user'
    ],
    components: {
        ActionModal,
    },
    computed: {
        // map `this.user` to `this.$store.getters.user`
        ...mapGetters({
            user: "user"
        }),
    },
    // beforeMount() {
    // async mounted() {
        // this.getReactions();
    //     this.initReactions();
    //     if (this.user.data) {
            
    //         this.showMyReactions();
    //     }
    // },
    watch: {
        "$store.getters.user.data": function () {
            if(this.user.data){
                this.showMyReactions();
            }
            this.initReactions();
        }
    },
    mounted(){
        this.initReactions();
        if(this.user.data){
            this.showMyReactions();
        }
    },

    methods: {
        // getReactions: function() {
        //     fetch(process.env.VUE_APP_API+`/recipes/reactions?id=`+this.recipe_id)
        //         .then(res => res.json())
        //         .then(res => {
        //             if(res.error){
        //             this.error = res.error;
        //             } else{
        //                 if(res.reaction && res.reaction.length < 1){
        //                     this.error = "No results";
        //                 }else{
        //                     console.log("i'm here");
        //                     this.reaction = res.reaction;
        //                 }
        //             }
        //         })
        //         .then(this.initReactions())
        //         .then(this.showMyReactions())
        //         ;
        // },
        initReactions() {
            let r = {
                    'yummy' : 0,
                    'gourmet' : 0,
                    'wellwritten': 0
                }
            this.reaction = {...r, ...this.reactions};
        },
        reactionClicked(reaction) {
            if (this.user.data) {

                console.log(reaction);
                fetch(process.env.VUE_APP_API+`/recipes/add_reaction`,{
                    method: "post",
                    headers: {
                        "TOKEN":JSON.stringify(this.user.data.token),
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                    "reaction":{
                        "recipe_id":this.recipe_id,
                        "name":reaction
                    }
                    })
                }).then(res => res.json())
                .then(res => {
                    if(res.error){
                        this.errors = res.error
                    }
                    else {
                        this.reaction[reaction] += (this.my_reactions[reaction] && this.reaction[reaction] > 0) ? -1 : +1;
                        this.my_reactions[reaction] = (this.my_reactions[reaction]) ? false : true;
                    }
                });
            }
            else {
                console.log("sorry, can't")
                this.show_login = true;
            }
        },
        showMyReactions() {

                if(this.$route.params.id){
                    fetch(process.env.VUE_APP_API+`/recipes/my_reactions`,{
                        method: "post",
                        headers: {
                            "TOKEN":JSON.stringify(this.user.data.token),
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "reactions":{
                                "recipe_id":this.$route.params.id
                            }
                        })
                    }).then(res => res.json())
                        .then(res => {
                            if(res.error){
                                this.errors = res.error;
                                console.log(res.error)
                            }
                            else {
                                this.my_reactions = {...this.my_reactions, ... res.my_reaction};
                            }
                    });
                }
        },
        gotoLogin() {
            this.$router.push({ path: '/login?fwd=/recipes/'+this.recipe_id})
            this.show_login = false;

        }

    }

    
}
</script>

<style lang="scss" scoped>
.first_info .reactions {
    justify-content: flex-start;
}
.reactions {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
}
.reaction {
  display: inline-flex;
  width: fit-content;
  margin: 0.2rem;
  border-radius: 50px;
  transition: all 0.2s ease;
  cursor: pointer;
//   flex-grow: 1;
  svg {
      width: 1.3rem;
      height: 1.3rem;
      transform: scale(1);
      transition: all 0.2s ease;
  }
  &:hover {
    &:after {
        content: "";
        position: absolute;
        margin-top: 2.4rem;
    }
    box-shadow: 0 2px 5px rgba(51, 51, 51, 0.25);
    svg {
        transform: scale(1.3);
        fill:var(--color-primary);
    }
  }
  &:active {
      svg {
          transform: scale(2);
        //   opacity: 0.5;
      }

  }
  &.voted {
    svg {
      fill:var(--color-secondary);
    }
  }
  span {
      margin:0.3rem 0.6rem 0.2rem 0.2rem;
  }

}
.yummy_reaction {
    &:hover:after {
        content: "Νοστιμιά!";
    }
}
.gourmet_reaction {
    &:hover:after {
        content: "Συγχαρητήρια!";
    }
}
.wellwritten_reaction {
    &:hover:after {
        content: "Αναλυτικά βήματα";
    }
}
  @media (max-width: 767.98px) {

  }

</style>