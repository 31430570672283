<template>
    <div class="container" >
    <!-- <div class="container÷" > -->

        <h1>h1 Κεντρικός τίτλος</h1>

        <button class="back_btn" type="button" @click="goBack()"> Πίσω </button>
        <BackButton/>

        <h2>h2 Αναζήτηση Υλικά Tίτλος συνταγής</h2>
        <h3>h3 Εισαγωγή - Βήματα</h3>
        <h4>h4 Οι συνταγές μου</h4>
        <h5>h5 Αναζήτηση</h5>
        <h6>h6 Αναζήτηση</h6>

        <p>Αυτό είναι ένα μικρό κείμενο.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </p>
        <p class="t-details-color font-size-sm style-italic">
            Λεπτομέρειες
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </p>

        <div class="step_form dashed-border-top">
            <button type="button" class="primary_btn del_btn"> - </button>
            <div class="description">
                <label for="description">Τίτλος ενότητας:</label>
                <input  type="text" name="description" id="description" class="text_input" required>
            </div>
        </div>
        <button type="button" class="primary_btn add_btn addsubstep_btn" style="display: block;">+
            <span class="tooltip_add">Προσθήκη βήματος</span>
        </button>

        <hr class="dashed-border-top"/>


        <input type="button" value="βασικό κουμπί" class="primary_btn" @click="changeMode()">
        <br>
        <input type="button" value="βασικό κουμπί" class="primary_btn warning_btn" >
        <br>
        <input type="button" value="δευτερεύον κουμπί" class="secondary_btn">
        <br>
        <input type="button" value="βασικός σύνδεσμος" class="primary_link">


         <div class="recipes_section container" >
            <span v-if="error">{{this.error}}</span>

            <div class="recipes_cards row no-gutters">
                <div v-for="result in results" :key="result.id" class=" col-md-4">
                    <RecipeCard v-bind:result="result"/>
                </div>
            </div>
        </div>

        <div class="color_palette dashed-border-top">
            <h5>Χρωματική παλέτα</h5>

            <div class="row">
                <div class="col c1">
                    <p>Χρώμα</p>
                    <div> </div>
                </div>
                <div class="col c2">
                    <p>Χρώμα</p>
                    <div> </div>
                </div>
                <div class="col c3">
                    <p>Χρώμα</p>
                    <div> </div>
                </div>
                <div class="col c4">
                    <p>Χρώμα</p>
                    <div> </div>
                </div>
                <div class="col c5">
                    <p>Χρώμα</p>
                    <div> </div>
                </div>
                <div class="col c6">
                    <p>Χρώμα</p>
                    <div> </div>
                </div>
                <div class="col c7">
                    <p>Χρώμα</p>
                    <div> </div>
                </div>
                <div class="col c7a">
                    <p>Χρώμα</p>
                    <div> </div>
                </div>
                <div class="col c8">
                    <p>Χρώμα</p>
                    <div> </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
import RecipeCard from './extras/Recipe_card.vue';
import BackButton from './extras/BackButton.vue';
export default {
  components: {
    RecipeCard,
    BackButton
  },
  name: 'Design',
  data(){
    return {
        error:false,
        mode: ""
    }
  },
  beforeMount(){
    this.getRecipes();
    // this.initMode();
  },
  props: {
  },
  computed: {
  },
  methods:{
        getRecipes: function() {
            this.results = [];
            fetch(process.env.VUE_APP_API+`/recipes/getall`)
                .then(res => res.json())
                .then(res => {
                    if(res.error){
                        this.error = res.error;
                    }else{
                        if(res.length < 1){
                            this.error = "No results";
                        }else{
                            this.results = res;
                        }
                    }
                });
        },
        initMode:function(){
            if (!localStorage.mode) {
                this.mode = "light";
            }
            else if (localStorage.mode == "dark") {
                this.mode="dark";
            }
        },
        changeMode:function() {
            if (localStorage.mode && localStorage.mode == "dark") {
                console.log("my mode is dark");
                localStorage.mode = "light";
                this.mode = "light";
                }
            else  {

                console.log("my mode is light");
                localStorage.mode = "dark";
                this.mode = "dark";
            }
        }
    }
}
</script>

<style scoped>
    .color_palette {
        margin-bottom: 2rem;
    }
    .color_palette .col div {
        width: 50px;
        height: 50px;
        border-radius: 10px;
    }
    .c1 p {     color:              var(--color-primary);  }
    .c1 div {   background-color:   var(--color-primary);  }
    .c2 p {     color:              var(--color-text-hover);   }
    .c2 div {   background-color:   var(--color-text-hover);    }
    .c3 p {     color:              var(--color-secondary);   }
    .c3 div {   background-color:   var(--color-secondary);    }
    .c4 p {     color:              var(--color-darker-blue);   }
    .c4 div {   background-color:   var(--color-darker-blue);  }
    .c5 p {     color:              var(--color-text-primary);  }
    .c5 div {   background-color:   var(--color-text-primary);  }
    .c6 p {     color:              var(--color-grey-1);    }
    .c6 div {   background-color:   var(--color-grey-1);    }
    .c7 p {     color:              var(--color-grey-2);    }
    .c7 div {   background-color:   var(--color-grey-2);    }
    .c7a p {     color:              var(--color-grey-3);    }
    .c7a div {   background-color:   var(--color-grey-3);    }
    .c8 p {     color:              var(--color-red);    }
    .c8 div {   background-color:   var(--color-red);    }


.step_form {
   display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
    margin-top: 1.3rem;
    align-items: flex-end;

}
.step_form div {
    display: flex;
    flex-wrap: wrap;
}
.step_form div * {
    width: 100%;
}
.description {
    width: 100%;
    text-align: left;
}
</style>
