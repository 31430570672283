<template>
    <div class="profile_container container" v-if="user && user.loggedIn">
        <h1>{{title}}</h1>

        <router-link :to="'/user'" class="back_btn">Πίσω </router-link>

        <!-- <div class="cover_photo">
            Προσθήκη Εικόνας +
        </div> -->
        <!-- <label for="cover_photo">Εικόνα εξωφύλλου</label> -->
        <input type="file" v-on:change="handleFileChange()" ref="cover_photo" name="cover_photo" id="cover_photo" accept="image/jpeg" class="text_input full_width_input cover_photo file_btn">

        <div class="profile_photo_div">
            <input type="file" v-on:change="handleProfilePhotoChange()" ref="profile_photo" name="profile_photo" id="profile_photo" class="text_input full_width_input profile_photo file_btn">
        </div>
        <div class="user_displayName">
          <h3> {{user.data.displayName}} </h3> 
        </div>

        <label for="about">Πληροφορίες:</label>
        <p class="subtext"> {{ $t('subtexts.markdown') }}</p>
        <textarea rows="5" name="about" id="about" class="text_input full_width_input about" v-model="user.data.profile.description" />
        
        <!-- <label for="email">Email:</label>
        <input type="text" name="email" id="email" class="text_input email" v-model="user.data.email"> -->
        <div class="btn_group">
          <button type="button" @click="$router.push({ path: '/user/'})" class="primary_link form_btn"> Πίσω στο προφίλ </button>
          <input type="button" @click="submitProfileChanges" value="Αποθήκευση Αλλαγών" class="primary_btn form_btn">
        </div>
    </div>

</template>

<script>

import { mapGetters } from "vuex";

export default {
    components: {
    },
    name: 'EditProfile',
    data(){
        return {
            title:"Επεξεργασία προφιλ",
            profile: {
                img:"@/assets/954.jpg",
                description:"Εδώ, μπορείτε να γράψετε λίγα λόγια για εσάς...",
            }
        }
    },
    computed: {
      // map `this.user` to `this.$store.getters.user`
      ...mapGetters({
        user: "user"
      })
    },
    methods: {
        handleFileChange(){
            this.cover_photo = this.$refs.cover_photo.files[0];
        },
        handleProfilePhotoChange(){
            this.profile_photo = this.$refs.profile_photo.files[0];
        },
        submitProfileChanges() {
            var profile_form = new FormData()
            profile_form.append('cover_photo', this.cover_photo)
            profile_form.append('profile_photo', this.profile_photo)
            profile_form.append('profile', JSON.stringify(this.user.data.profile))
            fetch(process.env.VUE_APP_API+`/user/profile`, { 
                    method: 'POST',
                    headers:{"TOKEN":JSON.stringify(this.user.data.token)},
                    body: profile_form
                })
                .then(res => res.json())
                .then(res => {
                    if(res.error){
                        this.error = res.error;
                    }else{
                        console.log(res)
                        // this.$router.push({ path: '/user'})
                        this.$router.push({ path: '/user/'+this.user.data.displayName})

                    }
                })

        }    
    }
}
</script>

<style scoped>

    .container {
        max-width: var(--max-width-container);
    }

    .text_input {
        /* width: 100%; */
        margin-bottom: 2rem;
    }

    label {
        font-size: 1.5rem;
        text-align: left;
        width: 100%;
    }
    .cover_photo {
        min-height: 250px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background-color: var(--color-grey-1);
    }

    .file_btn {
        cursor: pointer;
    }
    .file_btn::-webkit-file-upload-button {
    border: none;
    font-weight: 700;
    padding: 0.4rem 0.5rem;
    border-radius: 6px;
    font-size: 0.9rem;
    }

    .profile_photo::-webkit-file-upload-button {
        margin-top: 2rem;
        margin-left: -4px;

    }

    .profile_photo_div {
        background-color: white;
        border-radius: 70px;
        width: 140px;
        height: 140px;
        box-shadow: 0 8px 20px #00000030;
        margin: auto;
        position: relative;
        top: -70px;
        margin-bottom: -50px;
    }

    .profile_photo {
        border-radius: 70px;
        width: 90%;
        height: 90%;
        margin-top: 0.5rem;
        background-color: var(--color-grey-1);
    }

    h3 {
    font-weight: 700;
    }


</style>