<template>
    <div>
        <div class="step_form">
            <div>
                <button type="button" @click="emitDeleteEvent" class="primary_btn del_btn"> - </button>
            </div>
            <div class="description">
                    <label for="description">Τίτλος ενότητας: <MandatorySign v-bind:mandatory="true"/></label>
                    <input  type="text" v-bind:class="{ error: checkFieldError('description')}" v-model="description" v-on:input="updateValueToParent()" name="description" class="text_input" required>
            </div>

        </div>
        <div class="substeps_section">

            <div ref="substeps" class="substeps">
                <NewSubstep 
                v-model="substeps[index]"
                v-on:remove="removeSubstep(index)" 
                v-for="(substep,index) in substeps" 
                :key="substep.id || substep.idA" 
                :given_id="substep.idA || substep.id" 
                :given_description="substep.description"
                />
                
            </div>
            <button type="button" v-on:click="addNewSubstep" class="primary_btn add_btn addsubstep_btn">+
                <span class="tooltip_add">Προσθήκη βήματος</span>  
            </button>

        </div>
    </div>
</template>

<script>
import NewSubstep from "./New_substep.vue"
import MandatorySign from "../extras/Mandatory_sign.vue"
import Vue from 'vue'

export default {
    name:'NewStep',
    props: [ 
        'given_id',
        'given_description', 
        'given_substeps_data',
        'given_substeps',
        'given_substeps_counter',
        'errors',
        'shouldAddNewSubstep' 
    ],
    components: {
        NewSubstep ,
        MandatorySign
    },
    data(){
        return {
            img:"",
            id:this.$props.given_id,
            description:this.$props.given_description,
            substeps_data:this.$props.given_substeps_data || [],
            substeps: this.$props.given_substeps || [],
            // substeps:[],
            substeps_counter:this.$props.given_substeps_counter || 0
        }
    },
    beforeMount(){
        if(this.$props.shouldAddNewSubstep){
            this.addNewSubstep();
        }
    },
    methods:{
        updateValueToParent: function () {
            this.$emit('input', { 
                id:this.id,
                img:this.img,
                description:this.description,
                substeps:this.substeps,

            })
        },
        emitDeleteEvent(){
            this.$emit("remove")
        },

        addNewSubstep() {
            let a_substep = {
                id:this.substeps_counter++,
            }
            this.substeps.push(a_substep);
            this.substeps_data.push(a_substep);
            // this.updateValueToParent()
        },


        removeSubstep(sbst_id) {
           Vue.delete(this.substeps,sbst_id);

        },
        checkFieldError(field_name){
          return this.$props.errors && this.$props.errors.errors[field_name] != null;
        },




    }
    
}
</script>

<style scoped>

.step_form {
   display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
    margin-top: 1.3rem;
    align-items: flex-end;
}
.step_form div {
    display: flex;
    flex-wrap: wrap;
}
.step_form div * {
    width: 100%;
}
.description {
    width: 100%;
}
label {
    font-weight: bold;
    font-size: larger;
}

.substeps_section {
    margin-left: 2rem;
    padding-left: 0.7rem;
    border-left: 2px solid #05cec5;
}
.substeps {
    padding: 0rem 0.6rem 0.6rem;
    background-color: #f9f9f9;
    margin-bottom: 0.5rem;
    border-radius: 12px;
}
.dark .substeps {
    background-color: var(--color-text-primary);

}
.addsubstep_btn {
    margin-top: 1rem;
}

</style>