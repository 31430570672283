<template>
    <section>
        <div class="medium_container">
            <h1>Σχετικά με την πλατφόρμα</h1>   
            <img class="logo_icon" src="@/assets/logo.png" alt=""> 
            <p>Στο Cooking Handbook μπορείτε να αναζητήσετε έτοιμες συνταγές με βάση τα σκεύη, τα συστατικά και το χρόνο που έχετε στην διάθεσή σας. 
                Σας ενθαρρύνουμε, επίσης, να βαθμολογήσετε τις αγαπημένες σας συνταγές και να δημιουργήσετε την μαγειρική κοινότητα που σας εκφράζει. </p>
            <p>
                Η πλατφόρμα είναι δωρεάν και απευθύνεται προς όλους. Ανυπομονούμε να μας δείξετε τις συνταγές σας και να ακούσουμε την γνώμη σας.
            </p>
            <router-link to="/about-us" class="primary_link"  > Διαβάστε περισσότερα </router-link >
        </div>        
    </section>    
</template>

<script>
export default {
    name: 'AboutSection',
    data(){
        return {  
    
        }
    },
    components: {
    },
    methods:{
    }
}
</script>

<style lang="scss" scoped>
    p {
        text-align: center;
    }

</style>

